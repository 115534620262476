import { authorised } from "../../request";
import { VideoElement, VideoScene } from "../types/Video";

type Args = {
  videoId: string;
  fileId: string;
};

export type MLJSONSchema = {
  scenes: VideoScene[];
  elements: VideoElement[];
};

export async function downloadMLSchema(args: Args) {
  const API = authorised();

  return API.request<MLJSONSchema>({
    method: "GET",
    url: `/api/studio/themis/${args.videoId}/ml/schema/${args.fileId}/`,
  });
}
