import React, { useCallback, useEffect, useRef } from "react";
import getStroke from "perfect-freehand";
import { css } from "styled-components/macro";
import { Drawing } from "../types/Drawing";
import { getDrawingRect, getSvgPathFromStroke } from "../helpers/draw";
import produce from "immer";
import { VideoElementDrawConfig } from "../../VideoEditor/types/Video";
import { cloneDeep } from "lodash";
import { useDebounce } from "use-debounce";

export function DrawingRenderer(props: {
  drawing: Drawing | null;
  viewBox?: string;
  config: VideoElementDrawConfig;
  onPointerDown?: (e: React.PointerEvent<SVGSVGElement>) => void;
  onPointerMove?: (e: React.PointerEvent<SVGSVGElement>) => void;
  onPointerUp?: (e: React.PointerEvent<SVGSVGElement>) => void;
}) {
  return (
    <svg
      viewBox={props.viewBox}
      onPointerDown={props.onPointerDown}
      onPointerMove={props.onPointerMove}
      onPointerUp={props.onPointerUp}
      style={{ touchAction: "none", width: "100%", height: "100%" }}
    >
      {props.drawing &&
        props.drawing.marks.map((mark, idx) => {
          if (!props.drawing) return null;

          return (
            <path
              key={idx}
              fill={props.config.color}
              fillOpacity={props.config.opacity}
              d={getSvgPathFromStroke(
                getStroke(mark.points, {
                  size: props.config.lineWidth,
                  thinning: 0,
                  smoothing: 0.5,
                  streamline: 0.5,
                })
              )}
            />
          );
        })}
    </svg>
  );
}

const DrawAreaPure = (props: {
  config: VideoElementDrawConfig;
  onDrawEnd: (
    drawing: Drawing,
    data: {
      width: number;
      height: number;
      top: number;
      left: number;
    }
  ) => void;
}) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const [drawing, setDrawing] = React.useState<Drawing | null>(null);
  const [debouncedDrawing] = useDebounce(drawing, 2000);

  const drawingStartTime = useRef(0);

  useEffect(() => {
    if (!debouncedDrawing) return;

    const { left, top, width, height, trimmed } = getDrawingRect(
      debouncedDrawing,
      props.config
    );

    props.onDrawEnd(trimmed, {
      width,
      height,
      top: top[1],
      left: left[0],
    });
  }, [debouncedDrawing, props]);

  const handlePointerDown = useCallback(
    (e: React.PointerEvent<SVGSVGElement>) => {
      e.preventDefault();

      const rect = divRef.current?.getBoundingClientRect();

      if (!rect) return;

      // Mouse position
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      if (!drawingStartTime.current) {
        drawingStartTime.current = new Date().getTime();
      }

      setDrawing((d) => {
        const drawing = cloneDeep(d);
        const pointTime = new Date().getTime() - drawingStartTime.current;

        return {
          ...drawing,
          config: props.config,
          marks: [
            ...(drawing ? drawing.marks : []),
            {
              points: [[x, y, e.pressure, pointTime]],
            },
          ],
        };
      });
    },
    [props.config]
  );

  const handlePointerMove = useCallback(
    (e: React.PointerEvent<SVGSVGElement>) => {
      e.preventDefault();

      const rect = divRef.current?.getBoundingClientRect();

      if (!rect) return;

      // Mouse position
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      if (e.buttons === 1) {
        setDrawing((d) => {
          const drawing = cloneDeep(d);

          return produce(drawing, (drawingDraft) => {
            if (!drawingDraft) return drawingDraft;

            drawingDraft.marks[drawingDraft.marks.length - 1] = {
              points: [
                ...drawingDraft.marks[drawingDraft.marks.length - 1].points,
                [
                  x,
                  y,
                  e.pressure,
                  new Date().getTime() - drawingStartTime.current,
                ],
              ],
            };
          });
        });
      }
    },
    []
  );

  return (
    <div
      ref={divRef}
      css={css`
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      `}
    >
      <DrawingRenderer
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        drawing={drawing}
        config={props.config}
      />
    </div>
  );
};

export const DrawArea = React.memo(DrawAreaPure);
