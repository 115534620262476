import { authorised } from "../../request";
import { Image, ImageType } from "../../types/Image";

type Args = {
  file: File;
  title?: string | null;
  type?: ImageType;
  onUploadProgress?: (progressEvent: any) => void;
};

export async function uploadImage({
  file,
  title,
  type,
  onUploadProgress,
}: Args) {
  const API = authorised();
  const formData = new FormData();

  formData.append("file", file);

  if (title) {
    formData.append("title", title);
  }

  if (type) {
    formData.append("type", type);
  }

  const response = await API.request<Image>({
    method: "POST",
    url: `/api/studio/images/upload/`,
    data: formData,
    onUploadProgress,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
}
