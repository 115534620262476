import { authorised } from "../../request";
import { ChangeActionType } from "../db";
import { VideoElement } from "../types/Video";

export type VideoRemoteChange = {
  id: number;
};

export type RemoteCommitMeta = {
  userId: number;
  updateGroupId?: string | null;
};

const pushedChanges = new Map<string, boolean>();

export async function commitChange(input: {
  videoId: string;
  elementId?: string | null;
  content?: VideoElement | null;
  action: ChangeActionType;
  meta: RemoteCommitMeta;
  eventId: string;
}) {
  const API = authorised();
  if (pushedChanges.has(input.eventId)) return null;

  pushedChanges.set(input.eventId, true);

  return API.request<VideoRemoteChange>({
    method: "POST",
    url: `/api/studio/themis/${input.videoId}/changes/`,
    data: {
      update_group_id: input.meta.updateGroupId || null,
      video_id: input.videoId,
      action: input.action,
      element_id: input.elementId,
      content: input.content,
      event_id: input.eventId,
    },
  });
}
