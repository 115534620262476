import { CheckCircle, Circle } from "react-feather";
import { theme } from "../themes/variables";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";

function Instruction({
  label,
  match,
}: {
  label: string;
  match: RegExpMatchArray | null;
}) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      {match ? (
        <CheckCircle size={18} fill={theme.colors.greenMain} />
      ) : (
        <Circle size={18} />
      )}
      <span
        css={css`
          margin-left: 10px;
          margin-top: 2px;
        `}
      >
        {label}
      </span>
    </div>
  );
}

export default function PasswordInstructions({
  password,
}: {
  password: string;
}) {
  const { t } = useTranslation();

  const minLenght = new RegExp("^(?=.{9,150}$).*");
  const minUppercase = new RegExp("(?=.*[A-Z])");
  const minLowercase = new RegExp("(?=.*[a-z])");
  const minNumber = new RegExp("(?=.*[0-9])");
  const minSpecial = new RegExp(
    "(?=.*[!@#&$%^*+,.:;<=>?({//\\\\})\\[\\]`~|\"'_-])"
  );

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <div
        css={css`
          color: ${theme.colors.dark};
          padding: 7px 10px;
          font-size: 14px;
          border: 1px solid ${theme.colors.transparentBlack3};
          border-radius: 10px;
        `}
      >
        <Instruction
          match={password.match(minLenght)}
          label={t("password_instructions.length")}
        />
        <Instruction
          match={password.match(minUppercase)}
          label={t("password_instructions.uppercase")}
        />
        <Instruction
          match={password.match(minLowercase)}
          label={t("password_instructions.lowercase")}
        />
        <Instruction
          match={password.match(minNumber)}
          label={t("password_instructions.number")}
        />
        <Instruction
          match={password.match(minSpecial)}
          label={t("password_instructions.special")}
        />
      </div>
    </div>
  );
}
