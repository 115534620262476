import { VideoElementOpenQuestion, VideoElementState } from "../../types/Video";
import { css } from "styled-components/macro";
import { useCallback, useState } from "react";
import { debounce } from "lodash";
import { useStorage } from "../../contexts/StorageContext";
import striptags from "striptags";
import { useEditor } from "../../contexts/EditorContext";
import { calculateProportionalSize } from "../../helpers/renderer";
import { ContentEditable } from "../../components/ContentEditable";

export function ElementOpenQuestionRenderer(props: {
  element: VideoElementOpenQuestion;
  state: VideoElementState;
}) {
  const { api } = useStorage();
  const { canvasSize } = useEditor();
  const [title, setTitle] = useState(props.element.title || "Enter title here");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateElement = useCallback(
    debounce((elementId: string, title: string) => {
      api.updateElement(elementId, {
        title: title,
      });
    }, 1000),
    [api]
  );

  const handleContentEditable = (event: any) => {
    const content = striptags(event.target.value, [], "");

    setTitle(content);
    updateElement(props.element.id, content);
  };

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        border-radius: ${calculateProportionalSize(10, canvasSize.width)}px;
        background: #ffffff;
        padding: ${calculateProportionalSize(20, canvasSize.width)}px;
      `}
    >
      <ContentEditable
        style={{
          fontSize: calculateProportionalSize(15, canvasSize.width),
        }}
        html={title}
        onChange={handleContentEditable}
      />
      <p
        css={css`
          color: #cdcdcd;
          border-bottom: 1px solid #cdcdcd;
          margin: ${calculateProportionalSize(10, canvasSize.width)}px 0;
          font-size: ${calculateProportionalSize(10, canvasSize.width)}px;
        `}
      >
        Write down your answer
      </p>
      <p
        css={css`
          font-size: ${calculateProportionalSize(8, canvasSize.width)}px;
        `}
      >
        Disclaimer: The results are anonymous
      </p>
    </div>
  );
}
