// If you want to add new accepted type, you should also add the same at backend model
export enum AcceptedMimeTypes {
  MP3 = "audio/mp3",
  MPEG = "audio/mpeg",
  PDF = "application/pdf",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  XLS = "application/vnd.ms-excel",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PPT = "application/vnd.ms-powerpoint",
  PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  WEBM = "video/webm",
  WEBA = "audio/webm",
  SVG = "image/svg",
  TXT = ".txt",
  JPG = "image/jpg",
  PNG = "image/png",
  JPEG = "image/jpeg",
}

export type Transcript = {
  timecode: string;
  content: string;
};

export type File = {
  uuid: string;
  file: string;
  upload_name: string;
  title: string;
  mime_type: AcceptedMimeTypes;
  duration: number;
  transcript: Transcript[] | null;
};
