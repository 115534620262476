import React from "react";
import { GroupElementsFrame } from "../components/ElementControls/GroupElementsFrame";
import { useEditor } from "../contexts/EditorContext";
import { usePlayback } from "../contexts/PlaybackContext";
import { findElementState } from "../helpers/video";
import { VideoElement } from "../types/Video";
import { ElementRenderer } from "./ElementRenderer";

export type ElementsProps = {
  elements: VideoElement[];
};

function ElementsPure(props: ElementsProps) {
  const { currentFrame, currentTime } = usePlayback();
  const { activeElementsId } = useEditor();

  const activeElements = currentFrame.filter((element) =>
    activeElementsId.includes(element.id)
  );

  return (
    <>
      {props.elements &&
        props.elements.map((element) => {
          const state = findElementState(element, currentTime);

          if (!state) return null;

          return (
            <ElementRenderer key={element.id} element={element} state={state} />
          );
        })}

      {activeElements.length > 0 && (
        <GroupElementsFrame elements={activeElements} />
      )}
    </>
  );
}

export const Elements = React.memo(ElementsPure);
