import { VideoElement, VideoElementState } from "../../VideoEditor/types/Video";
import React from "react";
import { isEqual } from "lodash";
import { getAdjustedState, getElementRenderer } from "../helpers/renderer";
import { usePlayer } from "../contexts/PlayerContext";

const interactiveElements = ["audio", "open-question", "poll", "user-tagging"];

function ElementRendererPure(props: {
  element: VideoElement;
  state: VideoElementState;
}) {
  const { canvasSize } = usePlayer();
  const clickPosition = React.useRef({ x: 0, y: 0 });
  const Renderer = getElementRenderer(props.element);
  const adjustedState = getAdjustedState(props.state, canvasSize.width);

  return (
    <div
      style={{
        position: "absolute",
        width: adjustedState.width,
        height: adjustedState.height,
        transform: `translate3d(${adjustedState.left}px, ${adjustedState.top}px, 0)`,
        zIndex: props.element.order,
      }}
      onMouseDown={(e) => {
        clickPosition.current = {
          x: e.clientX,
          y: e.clientY,
        };
      }}
      onClick={(e) => {
        const isClick =
          Math.abs(clickPosition.current.x - e.clientX) < 5 &&
          Math.abs(clickPosition.current.y - e.clientY) < 5;

        if (!isClick || interactiveElements.includes(props.element.type)) {
          e.stopPropagation();
        }
      }}
    >
      <div
        style={{
          position: "absolute",
          transform: `rotate(${adjustedState.rotation}deg)`,
          width: adjustedState.width,
          height: adjustedState.height,
        }}
      >
        {Renderer && <Renderer element={props.element} state={adjustedState} />}
      </div>
    </div>
  );
}

export const ElementRenderer = React.memo(
  ElementRendererPure,
  (oldProps, newProps) => {
    const is = isEqual(oldProps, newProps);

    return is;
  }
);
