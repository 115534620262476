import { CollaboratorRole } from "../types/Collaborator";

export function canEdit(role?: CollaboratorRole | null) {
  if (!role) return false;

  if (role === CollaboratorRole.OWNER || role === CollaboratorRole.EDITOR)
    return true;

  return false;
}
