import { authorised } from "../../request";
import { UserProfile } from "../../types/UserProfile";

export async function updateMe(data: {
  first_name: string;
  last_name: string;
  introduction?: string;
  city: string;
}) {
  const API = authorised();

  return await API.request<UserProfile>({
    method: "PUT",
    url: `/api/studio/me/`,
    data
  });
}
