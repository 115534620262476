import React from "react";
import { useEditor } from "../../contexts/EditorContext";
import { useStorage } from "../../contexts/StorageContext";
import { calculateProportionalSize } from "../../helpers/renderer";
import { VideoElement } from "../../types/Video";
import { GroupElementsControls } from "./GroupElementsControls";

type GroupElementsFrameProps = {
  children?: React.ReactNode;
  elements: VideoElement[];
};

export function calculateFrameSize(elements: VideoElement[]) {
  const boundaries = elements.reduce(
    (acc, element) => {
      const { top, left, width, height } = element.states[0];

      return {
        top: Math.min(acc.top, top),
        left: Math.min(acc.left, left),
        right: Math.max(acc.right, left + width),
        bottom: Math.max(acc.bottom, top + height),
      };
    },
    {
      top: Infinity,
      left: Infinity,
      right: -Infinity,
      bottom: -Infinity,
    }
  );

  return {
    top: boundaries.top - 4,
    left: boundaries.left - 4,
    width: boundaries.right - boundaries.left + 8,
    height: boundaries.bottom - boundaries.top + 8,
  };
}

export function GroupElementsFrame(props: GroupElementsFrameProps) {
  const { canvasSize, wrapperRef } = useEditor();

  const { api } = useStorage();

  const frameSize = React.useMemo(() => {
    return calculateFrameSize(props.elements);
  }, [props.elements]);

  const dragHandler = (e: React.MouseEvent) => {
    if (!wrapperRef.current) return;

    const rect = wrapperRef.current.getBoundingClientRect();

    if (!rect) return;
  };

  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: calculateProportionalSize(frameSize.top, canvasSize.width),
          left: calculateProportionalSize(frameSize.left, canvasSize.width),
          width: calculateProportionalSize(frameSize.width, canvasSize.width),
          height: calculateProportionalSize(frameSize.height, canvasSize.width),
          border: "1px dashed black",
          cursor: "move",
          zIndex: 999,
        }}
        onMouseDown={(e) => dragHandler(e)}
      >
        <GroupElementsControls
          onDelete={() => api.deleteElements(props.elements)}
        />
      </div>
    </div>
  );
}
