import { theme } from "../themes/variables";

const brandedColors = [
  theme.colors.primary,
  theme.colors.primaryLighter,
  theme.colors.orange,
  theme.colors.blueMain,
  theme.colors.lightBlue,
  theme.colors.darkGreenMain,
  theme.colors.violetMain,
  theme.colors.yellowMain,
  theme.colors.lightRedMain,
  theme.colors.greenMain,
  theme.colors.redMain
];

const getValueOfText = (text: string): number => {
  let sum = 0;
  for (let i = 0; i < text.length - 1; i++)
    sum += text.charCodeAt(i);

  return sum;
}

export const getColorByText = (text: string) => {
  return brandedColors[getValueOfText(text) % brandedColors.length];
};