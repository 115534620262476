import React, { useCallback, useEffect, useRef, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { theme } from "../../../themes/variables";
import {
  Comment,
  CommentPostRequest,
  CommentVisibility,
} from "../../../types/Comment";
import { usePlayback } from "../../contexts/PlaybackContext";
import { useComments } from "../../contexts/CommentsContext";
import { useClickOutside } from "../../hooks/useClickOutside";
import { Button } from "../../../components/Button";
import { ModalDelete } from "../../../modals/ModalDelete";
import { CommentCard, CommentInput } from ".";
import { ReactComponent as CancelIcon } from "../../assets/icons/Cancel.svg";
import { useEditor } from "../../contexts/EditorContext";

type CommentBoxProps = {
  comments: Comment[];
  setExtended: (value: boolean) => void;
};

export function CommentBox(props: CommentBoxProps) {
  const { t } = useTranslation();

  const ref = useRef(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [content, setContent] = useState<string>("");
  const [editedContend, setEditedContent] = useState<string>("");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [extendedInput, setExtendedIntput] = useState<boolean>(false);
  const [selectedCommentId, setSelectedCommentId] = useState<number | null>(
    null
  );
  const { commentApi, setExpandedComment } = useComments();
  const { activeScene } = usePlayback();
  const { canvasSize } = useEditor();

  useClickOutside(ref, () => {
    if (!deleteModal) {
      props.setExtended(false);
      setExpandedComment(0);
    }
  });

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && !extendedInput) {
        props.setExtended(false);
        setExpandedComment(0);
      }
    };
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [props, setExpandedComment, extendedInput]);

  const clearInput = useCallback(() => {
    setSubmitting(false);
    setContent("");
    setEditMode(false);
    setSelectedCommentId(null);
    setExtendedIntput(false);
    setDeleteModal(false);
  }, []);

  const onSubmit = useCallback(() => {
    if (!activeScene) return;

    setSubmitting(true);

    const comment: CommentPostRequest = {
      scene_id: activeScene.id,
      visibility: CommentVisibility.CREATOR,
      content: content,
      parent_id: props.comments[0].id,
      top: 0,
      left: 0,
    };

    commentApi.createEditorComment(comment);

    clearInput();
  }, [activeScene, commentApi, content, clearInput, props.comments]);

  const onConfirmDelete = useCallback(() => {
    if (selectedCommentId) {
      commentApi.deleteEditorComment(selectedCommentId);
      clearInput();
    }
  }, [commentApi, selectedCommentId, clearInput]);

  const onConfirmEdit = useCallback(() => {
    if (selectedCommentId && editMode) {
      setSubmitting(true);

      commentApi.updateEditorComment({
        id: selectedCommentId,
        comment: editedContend,
      });

      clearInput();
    }
  }, [commentApi, editMode, editedContend, selectedCommentId, clearInput]);

  return (
    <div
      ref={ref}
      css={css`
        display: flex;
        position: absolute;
        width: 300px;
        max-width: 300px;
        height: auto;
        max-height: calc(${canvasSize.height + "px"} - 50px);
        flex-direction: column;
        background-color: ${theme.colors.white};
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 7px;
        padding: 8px 5px 0 12px;
        z-index: 1000;
      `}
    >
      <div
        css={css`
          margin-bottom: 14px;
        `}
      >
        <Button
          css={css`
            padding: 0;
            margin: 0 3px 0 auto;

            cursor: pointer;
            background: #e95b2e;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            min-width: 0;
          `}
          onClick={() => props.setExtended(false)}
          icon={
            <CancelIcon
              css={css`
                width: 7px;
                height: 7px; ;
              `}
            />
          }
          hoverStyles={"none"}
        />
      </div>
      <div
        css={css`
          overflow-y: scroll;

          ::-webkit-scrollbar {
            height: 100%;
            width: 6px;
            border-radius: 5px;
            scroll-margin-left: 5px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: transparent;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #8e8e8e;
            border-radius: 5px;
          }
        `}
      >
        {props.comments.map((comment, key) => (
          <CommentCard
            hideDropdown={key === 0 && props.comments.length > 1}
            key={key}
            comment={comment}
            setDeleteModal={() => setDeleteModal(true)}
            setSelectedCommentId={(commentId) =>
              setSelectedCommentId(commentId)
            }
            editMode={editMode}
            setEditMode={(value: boolean) => setEditMode(value)}
            setEditedContent={(value: string) => setEditedContent(value)}
          />
        ))}
      </div>
      <div
        css={css`
          border-top: 1px solid #8e8e8e;
          margin: auto 5px 0 0;
        `}
      >
        {editMode ? (
          <div
            css={css`
              display: flex;
              flex-direction: row;
              margin: 10px 0;
              justify-content: end;
            `}
          >
            <Button
              onClick={clearInput}
              css={css`
                background-color: transparent;
                min-width: 2em;
                color: #8e8e8e;
                border: 1px solid #8e8e8e;
                padding: 4px 17px;
                margin-right: 20px;
              `}
              hoverStyles={`color: #000; border-color:#000`}
            >
              {t("actions.cancel")}
            </Button>
            <Button
              onClick={onConfirmEdit}
              isSubmitting={isSubmitting}
              css={css`
                background-color: #e95b2e;
                color: #fff;
                min-width: 2em;
                padding: 4px 17px;
              `}
              hoverStyles={`color:#fff; border:none;background-color:#FE521C`}
            >
              {t("actions.save")}
            </Button>
          </div>
        ) : (
          <CommentInput
            comment={content}
            isSubmitting={isSubmitting}
            extended={extendedInput}
            setExtended={(value: boolean) => setExtendedIntput(value)}
            onSubmit={onSubmit}
            onChange={(el) => setContent(el.target.value)}
            clearInput={clearInput}
          />
        )}
      </div>
      <ModalDelete
        onDelete={onConfirmDelete}
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        title={t("actions.sure")}
      />
    </div>
  );
}
