import { CollaboratorRole } from "../types/Collaborator";
import { authorised } from "../../request";

export async function updateCollaborator(videoId: string, id: number, role: CollaboratorRole) {
  const API = authorised();

  return await API.request({
    method: "PUT",
    url: `/api/studio/themis/${videoId}/collaborators/${id}/`,
    data: {
      role
    }
  });
}