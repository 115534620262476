import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { css } from "styled-components/macro";

import { useFileUpload } from "../contexts/FileUploadContext";

import { uploadImage } from "../actions/uploadImage";
import { Image, ImageType } from "../types/Image";
import { Loader } from "./Loader";

export type DroppedImage = {
  preview: string;
  file: File;
};

export function DropImage(props: {
  initialMessage?: React.ReactNode | React.ReactNode[];
  loadingMessage?: React.ReactNode | React.ReactNode[];
  className?: string;
  onUpload: (image: Image, id: string) => void;
}) {
  const [uploading, setUploading] = useState(false);
  const { uploadFile, generateId } = useFileUpload();

  const { initialMessage, className } = props;

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file) {
        setUploading(true);
        const id = generateId();
        const { file: image } = await uploadFile(id, uploadImage, {
          file,
          title: file.name,
          type: ImageType.USER_UPLOADS,
        });

        if (image) {
          props.onUpload(image, id);
        }

        setUploading(false);
      }
    },
    [props, uploadFile, generateId]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      css={css`
        border-radius: 9px;
        background-color: #f2f2f2;
        padding: 9px;
      `}
    >
      <div
        {...getRootProps()}
        css={css`
          height: 106px;
          width: 100%;
          border: 1px dashed ${isDragActive ? "#FF812F" : "#000000"};
          background: transparent;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          ${isDragActive ? `background: #f5cdb3;` : ""}
        `}
        className={className}
      >
        <input {...getInputProps()} accept=".jpg,.png,.svg,.jpeg" />
        {uploading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div>{initialMessage}</div>
        )}
      </div>
    </div>
  );
}
