import { css } from "styled-components/macro";
import { VideoElementState, VideoElementPreview } from "../../types/Video";

import { Loader2 } from "../../../components/Loader";

export function ElementVideoPreviewRenderer(props: {
  element: VideoElementPreview;
  state: VideoElementState;
}) {
  const loaderSize = props.state.width / 3;

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        position: relative;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          user-select: none;
          overflow: hidden;
          border-radius: 50%;
        }
      `}
    >
      <video loop={true} autoPlay={true} muted>
        <source src={props.element.link} type="video/mp4" />
        Your browser does not support the video element.
      </video>

      <div css={css`
        position: absolute;
        left: calc(50% - ${css`${loaderSize / 2}px`});
        width: ${loaderSize}px;
        height: ${loaderSize}px;
        top: calc(50% - ${css`${loaderSize / 2}px`});
        z-index: 1;
      `}>
        <Loader2 color={'#E95B2E'} size={loaderSize} />
      </div>

      <div css={css`
        position: absolute;
        background: rgba(255, 255, 255, 0.3);
        width: ${props.state.width}px;
        height: ${props.state.height}px;
        top: 0;
        left: 0;
        border-radius: 50%;
      `}></div>
    </div>
  );
}
