import React, { ChangeEvent } from "react";
import { css } from "styled-components/macro";

type CommentEditFieldProps = {
  content: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
};

export function CommentEditField(props: CommentEditFieldProps) {
  return (
    <textarea
      cols={4}
      value={props.content}
      css={css`
        display: block;
        font-size: 16px;
        width: 100%;
        height: 100px;
        padding: 4px 10px;
        background-color: #f2f2f2;
        border: none;
        border-radius: 7px;
        outline: none;
        resize: none;
        ::-webkit-scrollbar {
          width: 0 !important;
        }
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
      `}
      onChange={(e) => {
        if (props.onChange) props.onChange(e);
      }}
    />
  );
}
