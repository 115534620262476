import React from "react";
import { css } from "styled-components/macro";
import { ReactComponent as DeleteIcon } from "../../assets/icons/ElementDelete.svg";
import { ElementButton } from "./ElementButton";

type GroupElementsControlsProps = {
  onDelete: () => {};
};

export function GroupElementsControls(props: GroupElementsControlsProps) {
  return (
    <>
      <div
        css={css`
          position: absolute;
          top: -15px;
          right: -15px;
          display: flex;
          justify-content: center;
        `}
      >
        <ElementButton onClick={props.onDelete}>
          <DeleteIcon />
        </ElementButton>
      </div>
    </>
  );
}
