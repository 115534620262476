import React from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { Spacing } from "../helpers/layout";

export function Error(props: {
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <div
      className={props.className}
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme.colors.dark};
        margin: ${Spacing.m};
        font-weight: bold;
        font-size: ${Spacing.l};
      `}
    >
      {props.children}
    </div>
  );
}
