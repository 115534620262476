import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

export function UploadSidebar() {
  const { t } = useTranslation();
  return (
    <div>
      <div
        css={css`
          width: 100%;
          height: 110px;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          background-color: #ffc39c;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        {t("resourses.sidebar.graphs.button.coming-soon")}
      </div>
    </div>
  );
}
