import React from "react";
import { Field, FieldProps } from "formik";
import { Image, ImageType } from "../../types/Image";
import { ImageUpload } from "./ImageUpload";
import { InputErrors } from "./InputErrors";
import { css } from "styled-components/macro";

export function ImageUploadField(props: {
  id?: string;
  name: string;
  label?: string;
  className?: string;
  disableLibrary?: boolean;
  hideTooltip?: boolean;
  errorBottom?: boolean;
  type?: ImageType;
  validate?: (value: string) => Promise<void | string>;
  uploadFn: (args: {
    file: File;
    title?: string | null;
    type?: ImageType;
    onUploadProgress?: (progressEvent: any) => void;
  }) => Promise<Image | string>;
}) {
  return (
    <Field name={props.name} validate={props.validate}>
      {({ field, meta, form }: FieldProps) => {
        const image: Image | string | null = field.value;

        return (
          <div
            css={css`
              position: relative;
            `}
            className={props.className}
          >
            <ImageUpload
              id={props.id}
              image={image}
              uploadFn={props.uploadFn}
              label={props.label}
              onChange={(img) => form.setFieldValue(props.name, img)}
              className={props.className}
              disableLibrary={props.disableLibrary}
              type={props.type}
            />
            {!props.hideTooltip && (
              <InputErrors error={meta.error} touched={meta.touched} />
            )}
            {props.errorBottom && meta.error && meta.touched && (
              <div
                css={css`
                  font-size: 16px;
                  margin-left: 25px;
                `}
              >
                {meta.error}
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
}
