import { authorised } from "../../request";
import { Comment, CommentVisibility } from "../../types/Comment";

export async function getComments(
  themi_id: string,
  visibility: CommentVisibility
) {
  const API = authorised();

  const response = await API.request<Comment[]>({
    method: "GET",
    url: `/api/studio/themis/${themi_id}/comments/?visibility=${visibility}`,
    data: themi_id,
  });

  return response.data;
}
