import { useEffect, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Comment } from "../../../types/Comment";
import { useAuth } from "../../../contexts/UserContext";
import { Headers } from "../../helpers/headers";
import { Avatar } from "../Avatar";
import { CommentEditField } from ".";
import { Dropdown, DropdownItem } from "../../../components/Dropdown";
import { ReactComponent as ThreeDotsIcon } from "../../../assets/icons/ThreeDots.svg";

type CommentCardProps = {
  hideDropdown: boolean;
  comment: Comment;
  setSelectedCommentId: (id: number) => void;
  setDeleteModal: () => void;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
  setEditedContent: (value: string) => void;
};

export function CommentCard(props: CommentCardProps) {
  const { userprofile } = useAuth();
  const [content, setContent] = useState<string>(props.comment.content);
  const [editComment, setEditComment] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setContent(props.comment.content);
  }, [props.comment.content]);

  const dropdownOptions = [
    {
      label: t("comment.edit"),
      onClick: () => {
        props.setSelectedCommentId(props.comment.id);
        props.setEditMode(true);
        setEditComment(true);
      },
    },
    {
      label: t("comment.delete"),
      onClick: () => {
        props.setSelectedCommentId(props.comment.id);
        props.setDeleteModal();
      },
    },
  ];

  useEffect(() => {
    props.editMode === false && setEditComment(false);
  }, [props.editMode]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-right: 5px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Avatar
          url={props.comment.author.avatar?.image}
          name={`${props.comment.author.first_name} ${props.comment.author.last_name}`}
          className="avatar"
          css={css`
            position: relative;
          `}
          border
          size={29}
        />

        <Headers.H4
          css={css`
            font-weight: 500;
            line-height: 21px;
            margin-left: 6px;
          `}
        >
          {props.comment.author.first_name +
            " " +
            props.comment.author.last_name}{" "}
        </Headers.H4>
        <span
          css={css`
            font-size: 14px;
            color: #8e8e8e;
            margin: 0 20px 0 auto;
          `}
        >
          {DateTime.fromISO(props.comment.created_at).toFormat("dd/MM/yyyy")}
        </span>
        {userprofile?.id === props.comment.author.id && !props.hideDropdown && (
          <Dropdown
            options={dropdownOptions.map((option, key) => {
              return (
                <DropdownItem key={key} onClick={option.onClick}>
                  {option.label}
                </DropdownItem>
              );
            })}
          >
            <ThreeDotsIcon
              css={css`
                cursor: pointer;
                align-items: center;
              `}
            />
          </Dropdown>
        )}
      </div>
      <div
        css={css`
          display: flex;
          margin: 9px 0 12px 0;
        `}
      >
        {editComment && props.editMode ? (
          <CommentEditField
            content={content}
            onChange={(event) => {
              setContent(event.target.value);
              props.setEditedContent(event.target.value);
            }}
          />
        ) : (
          <span
            css={css`
              font-size: 16px;
              margin-bottom: 16px;
            `}
          >
            {content}
          </span>
        )}
      </div>
    </div>
  );
}
