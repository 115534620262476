import React from "react";
import { css } from "styled-components/macro";

export function DisableUI(props: {
  show?: boolean;
  className?: string;
  children: React.ReactNode | React.ReactNode[] | string | number | null;
}) {
  const { show, children, className } = props;

  if (!show) return <>{children}</>;

  return (
    <div
      className={className}
      css={
        show &&
        css`
          pointer-events: none;
        `
      }
    >
      {children}
    </div>
  );
}
