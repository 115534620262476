import { authorised } from "../../request";
import { VideoPublishStatus } from "../../VideoEditor/types/Video";

export async function archiveVideo(themi_id:string,status:VideoPublishStatus){
    const API = authorised();
    const response = await API.request({
        method:"POST",
        url:`/api/studio/themis/${themi_id}/archive/`,
        data:status,
    })
    return response.data;

}
