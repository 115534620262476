import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { VideoElementUserTag } from "../VideoEditor/types/Video";
import { UserTagCard } from "./UserTagCard";

export const UserTagCardContainer = styled.div`
  z-index: 1000;
  background: #fff;
  border-radius: 7px;
  padding: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &:before {
    border-top: 10px solid #ccc;
  }

  &:after {
    border-top: 11px solid #fff;
    margin-top: -1px;
  }
`;
type PositionType = {
  left: number | null | any;
  top: number | null | any;
};

export function UserTagCardArea(props: {
  text?: any;
  children?: JSX.Element | string;
  className?: string;
  active?: boolean;
  setActive?: () => void;
  tag?: VideoElementUserTag | any;
  onInteraction?: () => void;
  onHover?: () => void;
  onBlur?: () => void;
}) {
  const [position, setPosition] = useState<PositionType>({
    left: null,
    top: null,
  });

  const { tag } = props;
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const containerHeight: any = wrapperRef.current?.clientHeight;
  const containerWidth: any = wrapperRef.current?.clientWidth;

  useEffect(() => {
    const targetRect = props.text?.current.getBoundingClientRect();
    setPosition({
      left: targetRect.left - containerWidth / 98,
      top: targetRect.top - containerHeight,
    });
  }, [containerWidth, containerHeight, props.text]);

  const isVisible = position.left && position.top;

  return (
    <UserTagCardContainer
      className={props.className}
      ref={wrapperRef}
      id="wrapper"
      style={{
        position: "absolute",
        left: position.left || 0,
        top: position.top || 0,
        width: "190px",
        visibility: !isVisible ? "hidden" : "visible",
        opacity: !isVisible ? 0 : 1,
        transition: "opacity 0.2s ease-in-out",
      }}
    >
      <UserTagCard tag={tag} />
    </UserTagCardContainer>
  );
}
