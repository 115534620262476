import { VideoElementPolygon, VideoElementState } from "../../types/Video";
import { css } from "styled-components/macro";
import { calculateProportionalSize } from "../../helpers/renderer";
import { useEditor } from "../../contexts/EditorContext";

export function ElementPolygonRenderer(props: {
  element: VideoElementPolygon;
  state: VideoElementState;
}) {
  const { config } = props.element;
  const width = props.state.width;
  const height = props.state.height;
  const { canvasSize } = useEditor();
  const strokeSize = calculateProportionalSize(
    config.strokeSize,
    canvasSize.width
  );

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
      `}
    >
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill={config.fillColor}
        fillOpacity={config.fillOpacity / 100}
        stroke={config.strokeColor}
        strokeWidth={strokeSize}
        strokeOpacity={config.strokeOpacity / 100}
      >
        <polygon
          points={`${width / 2},${strokeSize} ${strokeSize},${
            height - strokeSize
          } ${width},${height - strokeSize}`}
        />
      </svg>
    </div>
  );
}
