import React from "react";
import { css } from "styled-components/macro";
import { AlertCircle } from "react-feather";
import { Tooltip } from "../../../components/Tooltip";

export function InputErrors(props: {
  error?: string;
  touched?: boolean;
  className?: string;
}) {
  const { error, touched, className } = props;

  return (
    <div
      css={css`
        position: absolute;
        top: 17px;
        right: 0;
      `}
    >
      {error && touched && (
        <div
          css={css`
            cursor: pointer;
          `}
        >
          <Tooltip
            text={error}
            className={className}
            css={css`
              transform: translate(0, -50%);
              right: 0;
            `}
          >
            <AlertCircle size={18} color="#DB2136" />
          </Tooltip>
        </div>
      )}
    </div>
  );
}
