import { useEffect, useRef } from "react";
import markerSDK from "@marker.io/browser";
import { css } from "styled-components/macro";
import feedbackImage from "../assets/img/feedback.png";

export function FeedbackWidget() {
  const widget = useRef<any>(null);

  useEffect(() => {
    async function loadWidget() {
      widget.current = await markerSDK.loadWidget({
        destination: "63bbf9a774ef29c829e9bec6", // <- Your unique destination ID
      });
    }

    loadWidget();
  }, []);

  return (
    <div
      css={css`
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 77px;
        height: 77px;
        cursor: pointer;
        background: url(${feedbackImage});
        background-size: 100%;
      `}
      onClick={() => {
        if (widget.current) {
          widget.current.capture();
        }
      }}
    ></div>
  );
}
