import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { customToast } from "../../components/customToast";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { Video } from "../types/Video";

export enum LoadingStatus {
  NOT_STARTED = "Not Started",
  IN_PROGRESS = "In Progress",
  COMPLETED = "Completed",
  ERROR = "Error",
}

export type QueryUploadStructure = {
  error: string | null;
  status: LoadingStatus;
  loading: boolean;
  progress: number;
  onUploadProgress: (progressEvent: any) => void;
};

const FileUploadContext = React.createContext<{
  query: {
    [key: string]: QueryUploadStructure;
  };
  generateId: typeof uuid;
  getQueryById: (id: string) => QueryUploadStructure | null;
  uploadFile: <T, Y>(
    id: string,
    callback: (args: Y) => Promise<T>,
    args: Y
  ) => Promise<{
    file: T | null;
    id: string;
    query: QueryUploadStructure;
  }>;
}>({
  query: {},
  generateId: uuid,
  uploadFile: async () => {
    return {
      file: null,
      id: "",
      query: {
        error: null,
        status: LoadingStatus.NOT_STARTED,
        loading: false,
        progress: 0,
        onUploadProgress: () => {},
      },
    };
  },
  getQueryById: () => {
    return {
      error: null,
      status: LoadingStatus.NOT_STARTED,
      loading: false,
      progress: 0,
      onUploadProgress: () => {},
    };
  },
});

export function FileUploadProvider(props: {
  children: React.ReactNode;
  video: Video;
}) {
  const { children } = props;

  const [query, setQuery] = useState<{
    [key: string]: QueryUploadStructure;
  }>({});
  const { t } = useTranslation();

  const getQueryById = useCallback(
    (id: string) => {
      if (id in query) {
        return query[id];
      }

      return null;
    },
    [query]
  );

  const uploadFile = useCallback(
    async function <T, Y>(
      id: string,
      callback: (args: Y) => Promise<T>,
      args: Y
    ): Promise<{
      file: T | null;
      id: string;
      query: QueryUploadStructure;
    }> {
      /* init query ID */
      setQuery((query) => {
        query[id] = {
          error: null,
          status: LoadingStatus.NOT_STARTED,
          loading: false,
          progress: 0,
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            query[id]["progress"] = percentCompleted;
          },
        };
        return query;
      });

      try {
        setQuery((query) => {
          query[id].loading = true;
          query[id].status = LoadingStatus.IN_PROGRESS;
          return query;
        });

        const file = await callback({
          ...args,
          onUploadProgress: query[id].onUploadProgress,
        });

        setQuery((query) => {
          query[id].status = LoadingStatus.COMPLETED;
          return query;
        });

        return {
          file,
          id,
          query: query[id],
        };
      } catch (e: any) {
        setQuery((query) => {
          query[id].status = LoadingStatus.ERROR;
          query[id].error = e;
          return query;
        });

        customToast.error(
          t("status.error", {
            error: serverErrorHandler(e),
          })
        );

        return {
          file: null,
          id,
          query: query[id],
        };
      } finally {
        setQuery((query) => {
          query[id].loading = false;
          return query;
        });
      }
    },
    [query, t]
  );

  return (
    <FileUploadContext.Provider
      value={{
        query,
        generateId: uuid,
        getQueryById,
        uploadFile,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
}

export function useFileUpload() {
  const context = React.useContext(FileUploadContext);

  if (context === undefined) {
    throw new Error("useFileUpload must be used within a FileUploadProvider");
  }

  return context;
}
