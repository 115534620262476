import { notAuthorised } from "../../request";

export const USER_CONFIRM_EMAIL = "THEMIS_USER_CONFIRM_EMAIL";

export async function resendConfirmationEmail(email: string) {
  const API = notAuthorised;

  return await API.request({
    method: "POST",
    url: `/api/studio/resend-confirmation-email/`,
    data: {
      email,
    },
  });
}
