export const serverErrorHandler = (error: any) => {
  if (typeof error === "string") {
    return error;
  }

  return Object.entries(error.response.data)
    .map(([_, value]) => {
      return `${(Array.isArray(value) ? value.join(" ") : value)}`;
    })
    .join("\n");
}
