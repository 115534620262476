import React, { useCallback } from "react";
import { css } from "styled-components/macro";
import { VideoElementResizeDirection } from "../../types/Video";

type ElementResizerProps = {
  className?: string;
  initialDirection: VideoElementResizeDirection;
  rotation: number;
  resizeHandler: (
    e: React.MouseEvent,
    direction: VideoElementResizeDirection
  ) => void;
};

export function ElementResizer(props: ElementResizerProps) {
  const normalizeAngle = useCallback((angle: number) => {
    if (angle < 0) {
      return 360 + angle;
    }

    if (angle > 360) {
      return angle - 360;
    }

    return angle;
  }, []);

  function getAdjustedDirection(
    initialDirection: VideoElementResizeDirection,
    rotation: number
  ) {
    const resizeDirections: {
      [key in VideoElementResizeDirection]: number;
    } = {
      e: 0,
      se: 45,
      s: 90,
      sw: 135,
      w: 180,
      nw: 225,
      n: 270,
      ne: 315,
    };

    const directions = Object.keys(
      resizeDirections
    ) as VideoElementResizeDirection[];
    const currentAngle = resizeDirections[initialDirection];
    const newAngle = currentAngle + rotation;
    const closestDirection = directions.reduce((prev, curr) => {
      const prevDiff = normalizeAngle(
        Math.abs(resizeDirections[prev] - newAngle)
      );
      const currDiff = normalizeAngle(
        Math.abs(resizeDirections[curr] - newAngle)
      );

      return currDiff < prevDiff ? curr : prev;
    });

    return closestDirection;
  }

  const closestDirection = getAdjustedDirection(
    props.initialDirection,
    props.rotation
  );

  return (
    <div
      className={props.className}
      css={css`
        position: absolute;
        cursor: ${closestDirection}-resize;
      `}
      onMouseDown={(e) => props.resizeHandler(e, props.initialDirection)}
    />
  );
}
