import React, { ChangeEvent, useEffect, useRef } from "react";
import { css } from "styled-components/macro";
import { Comment } from "../../../types/Comment";
import { Avatar } from "../../../VideoEditor/components/Avatar";
import { Button } from "../../../VideoEditor/components/Button";
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/ArrowUp.svg";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "../../../VideoEditor/hooks/useClickOutside";

type CommpentInputProps = {
  comment: string;
  extended: boolean;
  setExtended: (value: boolean) => void;
  isSubmitting: boolean;
  onSubmit: () => void;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  commentToReply: Comment | null;
  removeReply: () => void;
  clearInput: () => void;
  isVisible: boolean;
};

export function CommentInput(props: CommpentInputProps) {
  const { t } = useTranslation();
  const ref = useRef(null);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.clearInput();
      }
    };
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [props, props.clearInput]);

  useEffect(() => {
    if (props.commentToReply) {
      props.setExtended(true);
    }
  }, [props, props.commentToReply, props.setExtended]);

  useClickOutside(ref, () => {
    if (!props.isVisible && props.comment.length === 0) {
      props.setExtended(false);
    }
  });

  return (
    <div
      ref={ref}
      css={css`
        display: flex;
        flex-direction: column;
        border-top: 1px solid #cdcdcd;
      `}
      onClick={() => !props.extended && props.setExtended(true)}
    >
      {props.commentToReply && (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            font-size: 16px;
            margin-bottom: 10px;
          `}
        >
          <span
            css={css`
              color: #8e8e8e;
              margin-right: 12px;
            `}
          >
            {t("comment.replying_to")}
          </span>
          <Avatar
            size={16}
            url={props.commentToReply.author.avatar?.image}
            border
            name={`${props.commentToReply.author.first_name} ${props.commentToReply.author.last_name}`}
          />
          <span
            css={css`
              font-weight: 600;
              color: #e95b2e;
              margin: 0 10px;
            `}
          >{`${props.commentToReply.author.first_name} ${props.commentToReply.author.last_name}`}</span>
          <Button
            icon={<Close />}
            onClick={props.removeReply}
            css={css`
              padding: 0;
              margin-top: 2px;
              cursor: pointer;
              background: none;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 13px;
              height: 13px;
              min-width: 0;
            `}
          />
        </div>
      )}
      {props.extended && (
        <textarea
          autoFocus
          cols={4}
          value={props.comment}
          css={css`
            display: block;
            font-size: 16px;
            width: 100%;
            height: 100px;
            padding: 10px 0;
            border: none;
            outline: none;
            resize: none;
            border-bottom: 1px solid #cdcdcd;
            ::-webkit-scrollbar {
              width: 0 !important;
            }
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
          `}
          onChange={(e) => {
            if (props.onChange) props.onChange(e);
          }}
        />
      )}
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 7px 0;
          cursor: ${props.extended ? "default" : "pointer"};
        `}
      >
        {!props.extended && (
          <span
            css={css`
              font-size: 16px;
              color: #8e8e8e;
            `}
          >
            {t("comment.add")}
          </span>
        )}
        <Button
          disabled={props.comment.length === 0}
          isSubmitting={props.isSubmitting}
          icon={<ArrowUp />}
          onClick={() => props.onSubmit()}
          css={css`
            padding: 0;
            margin: 0 0 0 auto;

            cursor: pointer;
            display: flex;
            background: #e95b2e;
            align-items: center;
            justify-content: center;
            width: 26px;
            height: 26px;
            min-width: 0;
          `}
        />
      </div>
    </div>
  );
}
