import { useCallback } from "react";
import { usePlayback } from "../../VideoEditor/contexts/PlaybackContext";
import { ReactComponent as PlayIcon } from "../assets/icons/Play.svg";
import { ReactComponent as PauseIcon } from "../assets/icons/Pause.svg";
import { css } from "styled-components/macro";

export function PlayButton() {
  const { playing, play, pause } = usePlayback();

  const onClick = useCallback(() => {
    if (playing) {
      pause();
    } else {
      play();
    }
  }, [playing, play, pause]);

  return (
    <button
      css={css`
        width: 40px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          fill: #e95b2e !important;
        }

        path {
          stroke-width: 0 !important;
        }
      `}
      onClick={onClick}
    >
      {playing ? <PauseIcon fill={"#e95b2e"} /> : <PlayIcon fill={"#e95b2e"} />}
    </button>
  );
}
