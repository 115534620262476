import { notAuthorised } from "../../request";

export async function resetPassword(email: string) {
  const API = notAuthorised;

  return await API.request<{
    token: string;
  }>({
    method: "POST",
    url: `/api/studio/reset-password/`,
    data: {
      email,
    },
  });
}
