import { usePlayback } from "../../VideoEditor/contexts/PlaybackContext";
import { findElementState } from "../../VideoEditor/helpers/video";
import { VideoElement } from "../../VideoEditor/types/Video";
import { ElementRenderer } from "./ElementRenderer";
import { css } from "styled-components/macro";
import { useCallback, useEffect, useState } from "react";

export function FrameRenderer(props: { elements: VideoElement[] }) {
  const { currentTime, playing, play, pause } = usePlayback();
  const [animated, setAnimated] = useState(false);

  const togglePlay = useCallback(() => {
    if (playing) {
      pause();
    } else {
      play();
    }
  }, [playing, play, pause]);

  useEffect(() => {
    setTimeout(() => {
      setAnimated(true);
    }, 500);
  }, []);

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.5s;

        ${animated && "opacity: 1;"}
      `}
      onClick={togglePlay}
    >
      {props.elements.map((element) => {
        const state = findElementState(element, currentTime);

        if (!state) return null;

        return (
          <ElementRenderer key={element.id} element={element} state={state} />
        );
      })}
    </div>
  );
}
