import React from "react";
import ReactDOM from "react-dom";
import { css } from "styled-components/macro";
import { ReactComponent as CancelIcon } from "../../VideoEditor/assets/icons/Cancel.svg";

export class ElementEditorPortal extends React.Component<{
  children?: React.ReactNode;
  onClose?: () => void;
}> {
  el: HTMLElement = document.createElement("div");
  portalRoot: HTMLElement = document.createElement("div");
  portalContainer: HTMLElement = document.createElement("div");

  componentDidMount() {
    this.portalRoot = document.getElementById(
      "editor-portal-root"
    ) as HTMLElement;
    this.portalContainer = document.getElementById(
      "editor-portal-container"
    ) as HTMLElement;

    const closeButton = document.getElementById(
      "editor-portal-close"
    ) as HTMLElement;

    this.portalContainer.appendChild(this.el);
    this.portalRoot.classList.add("active");

    this.portalRoot.addEventListener("click", (e) => {
      if (e.target === this.portalRoot) {
        this.props.onClose && this.props.onClose();
      }
    });

    closeButton.addEventListener("click", (e) => {
      this.props.onClose && this.props.onClose();
    });
  }

  componentWillUnmount() {
    this.portalContainer.removeChild(this.el);
    this.portalRoot.classList.remove("active");
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export function ElementEditorPortalRoot() {
  return (
    <div
      css={css`
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2000;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        opacity: 0;

        &.active {
          visibility: visible;
          opacity: 1;
        }
      `}
      id="editor-portal-root"
    >
      <div
        css={css`
          position: relative;
          padding: 50px 20px;
          background: #fff;
          border-radius: 20px;
        `}
      >
        <div id="editor-portal-container"></div>
        <button
          id="editor-portal-close"
          css={css`
            padding: 0;
            margin: 0;
            cursor: pointer;
            border: 0;
            -webkit-appearance: none;
            outline: none;
            background: #e95b2e;
            position: absolute;
            width: 18px;
            height: 18px;
            top: 20px;
            right: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <CancelIcon
            css={css`
              width: 7px;
              height: 7px;
            `}
          />
        </button>
      </div>
    </div>
  );
}
