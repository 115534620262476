import { useState, useEffect, useCallback } from "react";
import { AxiosResponse } from "axios";
import useDeepCompareEffect from "use-deep-compare-effect";
import { authorised } from "./request";

export async function fetch<T>(url: string) {
  const API = authorised();

  return await API.request<T>({
    method: "GET",
    url: `/api/studio/${url}`,
  });
}

export function useFetch<T>(url: string, initialValue: T) {
  const [data, setData] = useState<T>(initialValue);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async function () {
      try {
        setLoading(true);

        const response = await fetch<T>(url);

        setData(response.data);
      } catch (e: any) {
        setError(e.response.data.detail || "Error occured");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return {
    data,
    isLoading,
    setData: (data: T) => setData(data),
    error,
  };
}

export type UseNewFetchOptions = {
  enabled?: boolean;
};

export function useNewFetch<T, Y>(
  action: (args: Y) => Promise<AxiosResponse<T>>,
  args?: Y,
  options: UseNewFetchOptions = {}
) {
  const { enabled = true } = options;
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const localArgs = args || {};

  const fetchData = useCallback(
    async (funcArgs: Y) => {
      try {
        setLoading(true);
        const response = await action(funcArgs);
        setData(response.data);
      } catch (e: any) {
        setError(e.response.data.detail || "Error occured");
      } finally {
        setLoading(false);
      }
    },
    [action]
  );

  useDeepCompareEffect(() => {
    if (enabled) fetchData(localArgs as Y);
  }, [localArgs]);

  return {
    data,
    isLoading,
    setData: (newData: T | ((d: T | null) => T | null)) => {
      return setData(newData);
    },
    error,
    refetch: () => fetchData(localArgs as Y),
  };
}

export function useDetail<T>(url: string, id: string) {
  return useFetch<T | null>(`${url}/${id}/`, null);
}
