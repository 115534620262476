import React from "react";
import styled, { css } from "styled-components/macro";
import { ReactComponent as LeftIcon } from "../assets/icons/PaginationLeft.svg";
import { ReactComponent as RightIcon } from "../assets/icons/PaginationRight.svg";
import { theme } from "../themes/variables";
import { useTranslation } from "react-i18next";

const offset = 3;

function PaginationItem(props: {
  current?: boolean;
  children: string | React.ReactNode | React.ReactNode[];
  disabled?: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      css={css`
        cursor: pointer;
        display: flex;
        position: relative;
        color: #000;
        ${props.current &&
        css`
          color: ${theme.colors.primary};
          width: 21px;
          height: 21px;
          border: 1px solid ${theme.colors.primary};
          border-radius: 50%;
          justify-content: center;
          align-items: center;
        `}
        ${props.disabled &&
        css`
          pointer-events: none;
          opacity: 0;
        `}
      `}
      onClick={props.onClick}
      className={
        props.current
          ? "pagination__item pagination__item__current"
          : "pagination__item"
      }
    >
      {props.children}
    </div>
  );
}

const PaginationArrow = styled.button`
  display: flex;
  justify-content: center;
  font-weight: 600;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

export function Pagination(props: {
  infinite?: boolean;
  currentItemIndex: number;
  itemsCount: number;
  arrows?: boolean;
  onNext?: () => void;
  onPrev?: () => void;
  onChose?: (index: number) => void;
  className?: string;
}) {
  const { t } = useTranslation();
  const {
    currentItemIndex,
    itemsCount,
    onNext,
    onPrev,
    onChose = () => {},
    className,
    arrows,
  } = props;

  if (itemsCount <= 1) {
    return null;
  }

  const arr = Array(itemsCount)
    .fill(null)
    .map((_, i) => i);

  let wasShortenedLeft = false;
  let wasShortenedRight = false;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
      `}
      className={className}
    >
      {arrows && (
        <PaginationArrow onClick={onPrev}>
          <LeftIcon
            color={theme.colors.dark}
            css={css`
              cursor: pointer;
              opacity: 0.7;
              align-self: center;
              margin-right: 4px;
              :hover {
                opacity: 1;
              }
              ${currentItemIndex === 0 &&
              css`
                color: #e2e2e2;
                pointer-events: none;
              `}
            `}
          />
          <span
            css={css`
              ${currentItemIndex === 0 &&
              css`
                color: #e2e2e2;
                pointer-events: none;
              `}
            `}
          >
            {t("pagination.button.previous")}
          </span>
        </PaginationArrow>
      )}

      <ul
        css={css`
          display: flex;
          align-items: center;
          list-style-type: none;
          margin: 0 30px;
          padding: 0;
        `}
      >
        {arr.map((el) => {
          const canBeShort = arr.length > 5;
          const shortLeft =
            el > 0 && currentItemIndex - offset - 1 > el && el < arr.length - 1;
          const shortRight =
            el > 0 && currentItemIndex + offset - 1 < el && el < arr.length - 1;
          if (canBeShort && shortRight) {
            if (wasShortenedRight) return null;

            wasShortenedRight = true;

            return "...";
          }

          if (canBeShort && shortLeft) {
            if (wasShortenedLeft) return null;

            wasShortenedLeft = true;

            return "...";
          }

          return (
            <li
              key={el}
              css={css`
                line-height: 1;
                margin-right: 15px;
                font-size: 16px;
                line-height: 28px;

                &:last-child {
                  margin-right: 0;
                }
              `}
              onClick={() => onChose(el)}
            >
              <PaginationItem current={el === currentItemIndex}>
                {el + 1}
              </PaginationItem>
            </li>
          );
        })}
      </ul>

      {arrows && (
        <PaginationArrow onClick={onNext}>
          <span
            css={css`
              ${currentItemIndex + 1 === arr.length &&
              css`
                color: #e2e2e2;
                pointer-events: none;
              `}
            `}
          >
            {t("pagination.button.next")}
          </span>
          <RightIcon
            color={theme.colors.dark}
            css={css`
              cursor: pointer;
              opacity: 0.7;
              align-self: center;
              margin-left: 4px;
              :hover {
                opacity: 1;
              }
              ${currentItemIndex + 1 === arr.length &&
              css`
                color: #e2e2e2;
                pointer-events: none;
              `}
            `}
          />
        </PaginationArrow>
      )}
    </div>
  );
}
