import { authorised } from "../../request";

export async function deleteComment(comment_id: number, themi_id: string) {
  const API = authorised();
  const response = await API.request({
    method: "DELETE",
    url: `/api/studio/themis/${themi_id}/comments/${comment_id}/`,
  });

  return response.data;
}
