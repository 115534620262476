import {
  ReactionType,
  VideoElementReaction,
  VideoElementReactionConfig,
  VideoElementState,
} from "../../types/Video";
import { css } from "styled-components/macro";
import React from "react";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useEditor } from "../../contexts/EditorContext";
import { useStorage } from "../../contexts/StorageContext";
import { cloneDeep } from "lodash";
import {
  calculateProportionalSize,
  getReverseAdjustedState,
} from "../../helpers/renderer";

export const allowedReactions: Array<{
  name: ReactionType;
  icon: string;
}> = [
  {
    name: "thumbsup",
    icon: "👍",
  },
  {
    name: "thumbsdown",
    icon: "👎",
  },
  {
    name: "blush",
    icon: "😊",
  },
  {
    name: "heart",
    icon: "❤️",
  },
  {
    name: "thinking_face",
    icon: "🤔",
  },
  {
    name: "bulb",
    icon: "💡",
  },
  {
    name: "clap",
    icon: "👏",
  },
  {
    name: "tada",
    icon: "🎉",
  },
  {
    name: "slightly_frowning_face",
    icon: "🙁",
  },
];

export function ElementReactionRenderer(props: {
  element: VideoElementReaction;
  state: VideoElementState;
}) {
  const config: VideoElementReactionConfig = props.element.config || {
    reactions: [],
  };
  const { api } = useStorage();
  const { setActiveElementId, canvasSize } = useEditor();
  const [showSelector, setShowSelector] = React.useState(false);

  const popupRef = React.useRef<HTMLDivElement>(null);

  const emojiSize = calculateProportionalSize(25, canvasSize.width);
  const margin = calculateProportionalSize(5, canvasSize.width);
  const wrapperPadding = calculateProportionalSize(10, canvasSize.width);

  function calculateElementWidth(reactions: string[]) {
    return (
      (reactions.length + 1) * emojiSize +
      reactions.length * margin +
      wrapperPadding * 2
    );
  }

  const handleClose = () => {
    if (showSelector) setShowSelector(false);
  };

  useClickOutside(popupRef, handleClose);

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
        background: #fff;
        padding: ${Math.floor(wrapperPadding)}px;
        border-radius: 29px;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
      `}
    >
      {config.reactions.map((reactionName) => {
        const reaction = allowedReactions.find(
          (reactionType) => reactionType.name === reactionName
        );

        if (!reaction) return null;

        return (
          <div
            key={`reaction--${reactionName}`}
            css={css`
              position: relative;
              width: ${emojiSize}px;
              height: ${emojiSize}px;
              line-height: ${emojiSize}px;
              font-size: ${emojiSize * 0.66}px;
              margin-right: ${margin}px;

              &:last-child {
                margin-right: 0;
                padding-right: 0;
              }

              &:hover .remove-reaction {
                opacity: 1;
                visibility: visible;
              }
            `}
          >
            {reaction.icon}

            <button
              className="remove-reaction"
              css={css`
                position: absolute;
                width: 14px;
                height: 14px;
                right: 0px;
                top: 0;
                border-radius: 50%;
                background: #e95b2e;
                border: none;
                cursor: pointer;
                font-size: 20px;
                transform: rotate(45deg);
                color: #fff;
                font-size: 14px;
                line-height: 14px;
                opacity: 0;
                visibility: hidden;
              `}
              onClick={() => {
                const newConfig = cloneDeep(config);

                newConfig.reactions = config.reactions.filter(
                  (reaction) => reaction !== reactionName
                );

                api.updateElement(props.element.id, {
                  config: newConfig,
                  states: [
                    getReverseAdjustedState(
                      {
                        ...props.state,
                        width: calculateElementWidth(newConfig.reactions),
                      },
                      canvasSize.width
                    ),
                  ],
                });
              }}
            >
              +
            </button>
          </div>
        );
      })}
      <button
        css={css`
          position: relative;
          width: ${emojiSize * 0.66}px;
          height: ${emojiSize * 0.66}px;
          border-radius: 50%;
          padding: 0;
          border: none;
          background: #fff;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
          cursor: pointer;
          font-size: ${emojiSize * 0.5}px;
        `}
        onClick={(e) => {
          e.stopPropagation();
          setActiveElementId(null);
          setShowSelector(true);
        }}
      >
        +
        {showSelector && (
          <div
            ref={popupRef}
            css={css`
              position: absolute;
              bottom: calc(100% + ${props.state.height / 2}px);
              left: 50%;
              margin-left: -${(allowedReactions.length * emojiSize) / 2 + 10}px;
              padding: 10px;
              background: white;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              border-radius: 7px;
              width: ${allowedReactions.length * emojiSize}px;
              box-sizing: content-box;

              &::before {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid white;
              }
            `}
            onClick={(e) => e.stopPropagation()}
          >
            {allowedReactions.map((reaction) => {
              return (
                <button
                  css={css`
                    width: ${emojiSize};
                    height: ${emojiSize};
                    flex: 0 0 ${emojiSize}px;
                    border: none;
                    background: none;
                    font-size: ${emojiSize * 0.66}px;
                    padding: 0;
                    cursor: pointer;
                    border-radius: 50%;
                    background-color: #fff;
                    transition: all 200ms;

                    &:last-child {
                      margin-right: 0;
                    }

                    &:hover {
                      background: #eee;
                    }

                    ${config.reactions.includes(reaction.name) &&
                    css`
                      opacity: 0.4;
                      pointer-events: none;
                    `}
                  `}
                  onClick={() => {
                    setShowSelector(false);

                    const newConfig = cloneDeep(config);

                    newConfig.reactions = [
                      ...newConfig.reactions,
                      reaction.name,
                    ];

                    api.updateElement(props.element.id, {
                      config: newConfig,
                      states: [
                        getReverseAdjustedState(
                          {
                            ...props.state,
                            width: calculateElementWidth(newConfig.reactions),
                          },
                          canvasSize.width
                        ),
                      ],
                    });
                  }}
                >
                  {reaction.icon}
                </button>
              );
            })}
          </div>
        )}
      </button>
    </div>
  );
}
