import styled from "styled-components/macro";
import RotateIcon from "../../assets/icons/RotateBottomRight.svg";

export const ElementRotator = styled.button`
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-image: url(${RotateIcon});
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  border: none;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;
