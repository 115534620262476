import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { theme } from "../../../themes/variables";
import {
  Comment,
  CommentPostRequest,
  CommentVisibility,
} from "../../../types/Comment";
import { useViewerComments } from "../../contexts/ViewerCommentsContext";
import { usePlayback } from "../../../VideoEditor/contexts/PlaybackContext";
import { CommentsThread, CommentInput } from ".";
import { ModalDelete } from "../../../modals/ModalDelete";
import { usePlayer } from "../../contexts/PlayerContext";

type CommensBoxProps = {
  comments?: Comment[];
};

export function CommentsBox(props: CommensBoxProps) {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selectedCommentId, setSelectedCommentId] = useState<number | null>(
    null
  );
  const [extendedInput, setExtendedIntput] = useState<boolean>(false);
  const [reply, setReply] = useState<Comment | null>(null);
  const [content, setContent] = useState<string>("");
  const { activeScene, currentTime } = usePlayback();
  const { canvasSize } = usePlayer();

  const { comments, commentApi, closeCommentsThread } = useViewerComments();

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && !extendedInput) {
        closeCommentsThread();
      }
    };
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [closeCommentsThread, extendedInput]);

  const clearInput = useCallback(() => {
    setContent("");
    setSelectedCommentId(null);
    setReply(null);
    setExtendedIntput(false);
    setDeleteModal(false);
    setSubmitting(false);
  }, []);

  const onSubmit = useCallback(() => {
    setSubmitting(true);

    const parentId = reply && reply.id ? reply.id : undefined;
    const timeline = parentId ? undefined : Math.round(currentTime);

    const comment: CommentPostRequest = {
      scene_id: activeScene!.id,
      top: 0,
      left: 0,
      visibility: CommentVisibility.VIEWER,
      content: content,
      parent_id: parentId,
      timeline: timeline,
    };

    commentApi.createViewerComment(comment);

    clearInput();
  }, [activeScene, commentApi, content, currentTime, reply, clearInput]);

  const onConfirmDelete = useCallback(() => {
    if (selectedCommentId) {
      commentApi.deleteViewerComment(selectedCommentId);
      clearInput();
    }
  }, [commentApi, selectedCommentId, clearInput]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        width: 420px;
        min-height: ${comments.length > 0 && `340px`};
        max-height: calc(${canvasSize.height + "px"} - 200px);
        background-color: ${theme.colors.white};
        border: 1px solid #d9d9d9;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        right: 0;
        padding: 40px 10px 10px 15px;
      `}
    >
      <div
        css={css`
          max-height: 550px;
          overflow-y: scroll;

          ::-webkit-scrollbar {
            height: 100%;
            width: 6px;
            border-radius: 5px;
            scroll-margin-left: 5px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: transparent;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #8e8e8e;
            border-radius: 5px;
          }
        `}
      >
        {comments &&
          comments.map((comments, key) => (
            <CommentsThread
              key={key}
              comments={comments}
              setSelectedCommentId={(id: number) => setSelectedCommentId(id)}
              setDeleteModal={() => setDeleteModal(true)}
              isReplyActive={!!reply && reply.id === comments[0].id}
              setReply={(comment: Comment | null) => setReply(comment)}
            />
          ))}
      </div>

      <div
        css={css`
          margin: auto 0 0 0;
        `}
      >
        <CommentInput
          comment={content}
          isSubmitting={isSubmitting}
          extended={extendedInput}
          setExtended={(value: boolean) => setExtendedIntput(value)}
          onSubmit={onSubmit}
          onChange={(el) => setContent(el.target.value)}
          commentToReply={reply}
          removeReply={() => setReply(null)}
          clearInput={clearInput}
          isVisible={deleteModal}
        />
      </div>
      <ModalDelete
        onDelete={onConfirmDelete}
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        title={t("actions.sure")}
      />
    </div>
  );
}
