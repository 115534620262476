import React from "react";
import { getDrawingRect } from "../../../VideoEditor/helpers/draw";
import { DrawingRenderer } from "../../../VideoEditor/components/DrawArea";
import { VideoElement } from "../../../VideoEditor/types/Video";
import { usePlayback } from "../../../VideoEditor/contexts/PlaybackContext";
import { Drawing } from "../../../VideoEditor/types/Drawing";

const getFilteredDrawing = (drawing: Drawing, drawingTime: number) => {
  const filteredDrawing = {
    ...drawing,
    marks: drawing.marks.map((mark) => {
      return {
        ...mark,
        points: mark.points.filter((point) => point[3] <= drawingTime),
      };
    }),
  };

  return {
    ...filteredDrawing,
    marks: filteredDrawing.marks.filter((mark) => {
      return mark.points.length > 0;
    }),
  };
};

export function ElementDrawingRenderer(props: { element: VideoElement }) {
  const { element } = props;
  const { currentTime } = usePlayback();

  if (element.type !== "drawing") return null;

  const { width, height } = getDrawingRect(element.drawing, element.config);
  const drawingStartTime = element.states[0].start_time;
  const drawingTime = currentTime - drawingStartTime;

  const drawing =
    element.config.mode === "highlight"
      ? element.drawing
      : getFilteredDrawing(element.drawing, drawingTime);

  return (
    <DrawingRenderer
      drawing={drawing}
      viewBox={`0 0 ${width} ${height}`}
      config={element.config}
    />
  );
}
