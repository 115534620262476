import { authorised } from "../../request";
import { Video } from "../types/Video";

type VideoData = Pick<Video, "name">;

export async function updateVideo(videoId: string, data: VideoData) {
  const API = authorised();

  const response = await API.request<Video>({
    method: "PUT",
    url: `/api/studio/themis/${videoId}/`,
    data,
  });

  return response.data;
}
