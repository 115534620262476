import styled, { css } from "styled-components/macro";

export const Separator = styled.hr`
  margin: 0 !important;
  padding: 0;
  border: none;
  outline: none;
  flex-grow: 1;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px;
`;

export const DashedSeparator = styled.hr`
  margin: 0 !important;
  padding: 0;
  border: none;
  outline: none;
  flex-grow: 1;
  border-bottom: 1px dashed #e0e0e0;
  border-radius: 10px;
`;

export function LongDashedSeparator(props: { color?: string }) {
  return (
    <div
      css={css`
        height: 1px;
        flex-grow: 1;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23${props.color ||
        "979797"}' stroke-width='2' stroke-dasharray='6%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      `}
    ></div>
  );
}
