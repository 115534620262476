import { authorised } from "../../request";
import { Video } from "../types/Video";

export async function deleteVideo(videoId: string) {
  const API = authorised();

  const response = await API.request<Video>({
    method: "DELETE",
    url: `/api/studio/themis/${videoId}/`,
  });

  return response.data;
}
