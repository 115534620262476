import { css } from "styled-components/macro";
import { Comment } from "../../../types/Comment";
import { theme } from "../../../themes/variables";
import { Collaborators } from "../../components/Collaborators";

type CommentMarkerProps = {
  comments: Comment[];
  setExtended: (value: boolean) => void;
};

export function CommentMarker(props: CommentMarkerProps) {
  function getUsers() {
    const users = props.comments
      .map((comment) => comment.author)
      .filter(
        (user, index, self) =>
          self.findIndex((el) => el.id === user.id) === index
      );

    return users;
  }

  return (
    <div
      css={css`
        position: absolute;
        border-radius: 24px 24px 24px 0;
        background-color: ${theme.colors.white};
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
        padding: 9px 11px;
        cursor: pointer;
        z-index: 999;
      `}
      onClick={() => {
        props.setExtended(true);
      }}
    >
      <Collaborators users={getUsers()} maxCollaborators={4} />
    </div>
  );
}
