import { notAuthorised } from "../../request";
import { UserProfile } from "../../types/UserProfile";

export async function login(username: string, password: string) {
  const API = notAuthorised;

  return await API.request<{
    userprofile: UserProfile;
    token: string;
    is_verified: boolean;
    email_confirmed: boolean;
  }>({
    method: "POST",
    url: `/api/studio/login/`,
    data: {
      username,
      password,
    },
  });
}
