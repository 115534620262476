import { authorised } from "../../request";

export async function removeCollaborator(videoId: string,id:number) {
  const API = authorised();

  const response = await API.request({
    method: "DELETE",
    url:`/api/studio/themis/${videoId}/collaborators/${id}/`,
  });

  return response.data;
}