import Pusher from "pusher-js";
import { THEMIS_AUTH_TOKEN } from "../../contexts/UserContext";

// Enable pusher logging - don't include this in production
Pusher.logToConsole = false;

if (process.env.REACT_APP_PUSHER_KEY === undefined) {
  throw new Error("REACT_APP_PUSHER_KEY not set");
}

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: "eu",
  channelAuthorization: {
    endpoint: `${process.env.REACT_APP_BACKEND_URL}/api/studio/pusher/auth/`,
    transport: "ajax",
    headers: {
      Authorization: `Token ${localStorage.getItem(THEMIS_AUTH_TOKEN)}`,
    },
  },
});
