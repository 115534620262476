import { authorised } from "../../request";

export async function submitPoll(
  themiId: string,
  pollId: string,
  data: {
    votes: string[];
  }
) {
  const API = authorised();

  return await API.request({
    method: "POST",
    url: `/api/studio/themis/${themiId}/polls/${pollId}/votes/`,
    data,
  });
}
