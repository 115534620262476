import { notAuthorised } from "../../request";

export async function setPassword(password: string, uuid: string) {
  const API = notAuthorised;

  return await API.request<{
    token: string;
  }>({
    method: "POST",
    url: `/api/studio/set-password/${uuid}/`,
    data: {
      password,
    },
  });
}
