import React, { useCallback, useEffect, useMemo } from "react";

import { customToast } from "../../components/customToast";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { useTranslation } from "react-i18next";

import {
  Comment,
  CommenPutRequest,
  CommentPostRequest,
  CommentVisibility,
} from "../../types/Comment";
import {
  createComment,
  deleteComment,
  getComments,
  updateComment,
} from "../../actions/comments";
import { pusher } from "../../VideoEditor/helpers/pusher";
import { sortBy } from "lodash";

const ViewerCommentsContext = React.createContext<{
  comments: Array<Comment>[];
  commentsThreadOpen: boolean;
  openCommentsThread: () => void;
  closeCommentsThread: () => void;
  toggleCommentsThread: () => void;

  commentApi: {
    createViewerComment: (comment: CommentPostRequest) => Promise<void>;
    updateViewerComment: (comment: CommenPutRequest) => Promise<void>;
    deleteViewerComment: (commentId: number) => Promise<void>;
  };
}>({
  comments: [],
  commentsThreadOpen: false,
  openCommentsThread: () => {},
  closeCommentsThread: () => {},
  toggleCommentsThread: () => {},

  commentApi: {
    createViewerComment: () => Promise.resolve(),
    updateViewerComment: () => Promise.resolve(),
    deleteViewerComment: () => Promise.resolve(),
  },
});

export function ViewerCommentsProvider(props: {
  children: React.ReactNode;
  videoId: string;
}) {
  const { t } = useTranslation();

  const [comments, setComments] = React.useState<Comment[]>([]);
  const [commentsThreadOpen, setCommentThreadOpen] = React.useState(false);

  const fetchViewerComments = useCallback(async () => {
    try {
      const comments = await getComments(
        props.videoId,
        CommentVisibility.VIEWER
      );

      setComments(comments);
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  }, [t, props.videoId]);

  useEffect(() => {
    fetchViewerComments();
  }, [fetchViewerComments]);

  const createViewerComment = useCallback(
    async (comment: CommentPostRequest) => {
      try {
        await createComment(comment, props.videoId);
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    },
    [t, props.videoId]
  );

  const updateViewerComment = useCallback(
    async (comment: CommenPutRequest) => {
      try {
        await updateComment(comment, props.videoId);
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    },
    [t, props.videoId]
  );

  const deleteViewerComment = useCallback(
    async (commentId: number) => {
      try {
        await deleteComment(commentId, props.videoId);
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    },
    [t, props.videoId]
  );

  useEffect(() => {
    const channel = pusher.subscribe(
      `presence-video--${props.videoId}--${CommentVisibility.VIEWER}`
    );

    channel.bind("new-comment", (comment: Comment) => {
      setComments((comments) => [...comments, comment]);
    });
    channel.bind("delete-comment", (comment: { id: number }) => {
      setComments((comments) => comments.filter((c) => c.id !== comment.id));
    });
    channel.bind("edit-comment", (comment: { id: number; content: string }) => {
      setComments((comments) => {
        return comments.map((c) => {
          if (c.id === comment.id) {
            return {
              ...c,
              content: comment.content,
            };
          }
          return c;
        });
      });
    });

    return () => {
      pusher.unsubscribe(`presence-video--${props.videoId}`);
    };
  }, [props.videoId]);

  const sortedCommentsByParentId = () => {
    const rootComments = comments.filter((comment) => comment.parent === null);

    if (!rootComments.length) return [];

    const result: any[] = [];
    rootComments.forEach((rootComment) => {
      const thread = [rootComment];
      comments.forEach((comment) => {
        if (comment.parent === rootComment.id) {
          thread.push(comment);
        }
      });
      result.push(thread);
    });

    return sortBy(result, "id").reverse();
  };

  const commentApi = useMemo(
    () => ({
      createViewerComment,
      updateViewerComment,
      deleteViewerComment,
    }),
    [createViewerComment, updateViewerComment, deleteViewerComment]
  );

  return (
    <ViewerCommentsContext.Provider
      value={{
        comments: sortedCommentsByParentId(),
        commentsThreadOpen,
        openCommentsThread: () => setCommentThreadOpen(true),
        closeCommentsThread: () => setCommentThreadOpen(false),
        toggleCommentsThread: () => setCommentThreadOpen((open) => !open),

        commentApi,
      }}
    >
      {props.children}
    </ViewerCommentsContext.Provider>
  );
}
export function useViewerComments() {
  const context = React.useContext(ViewerCommentsContext);
  if (context === undefined) {
    throw new Error(
      "useViewerComments must be used within a ViewerCommentsProvider"
    );
  }
  return context;
}
