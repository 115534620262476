import { authorised } from "../../request";
import { ImageTag } from "../../types/Image";

export async function getImageTags() {
  const API = authorised();

  return API.request<ImageTag[]>({
    method: "GET",
    url: `/api/studio/image-tags/`,
  });
}
