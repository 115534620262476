import {
  VideoElementUserTagging,
  VideoElementState,
} from "../../../VideoEditor/types/Video";
import { css } from "styled-components/macro";
import { calculateProportionalSize } from "../../helpers/renderer";
import { Avatar } from "../../../VideoEditor/components/Avatar";
import { usePlayer } from "../../contexts/PlayerContext";
import { sendEvent } from "../../../helpers/tracking";
import { UserTagCardArea } from "../../../components/UserTagCardArea";
import { ElementUserTagPortal } from "../../../VideoEditor/portals/ElementUserTagPortal";
import { useRef, useState } from "react";

export function ElementUserTaggingRenderer(props: {
  element: VideoElementUserTagging;
  state: VideoElementState;
}) {
  const [hoveredId, setHoveredId] = useState<string | null>(null);
  const { canvasSize, video } = usePlayer();
  const targetRef = useRef<HTMLDivElement | null>(null);
  const tags = props.element.tags || [];

  const tagSize = calculateProportionalSize(30, canvasSize.width);
  const margin = calculateProportionalSize(5, canvasSize.width);

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
      `}
    >
      {tags.map((tag, index) => {
        const borderSize = calculateProportionalSize(2, canvasSize.width);
        const itemSize = tagSize - borderSize * 2;

        return (
          <div
            css={css`
              position: relative;
              margin-right: ${margin}px;
              cursor: pointer;

              &:hover {
                z-index: 300;

                .tag-card {
                  opacity: 1;
                  visibility: visible;
                }
              }
            `}
            style={{
              transform: `rotate(${0 - props.state.rotation}deg)`,
            }}
            key={index}
            onMouseOver={() => setHoveredId(tag.id)}
            onMouseEnter={() => setHoveredId(tag.id)}
            onMouseLeave={() => setHoveredId(null)}
          >
            <Avatar
              size={itemSize}
              name={tag.name}
              url={tag.avatar?.image}
              border
            />
            {hoveredId === tag.id && (
              <div
                css={css`
                  position: absolute;
                  width: 190px;
                  bottom: 100%;
                  left: 50%;
                  transform: translate(-50%, -10px);
                  opacity: 0;
                  visibility: hidden;
                  z-index: 200;
                  opacity: 0;
                `}
                className="tag-card"
                ref={targetRef}
              >
                <ElementUserTagPortal>
                  <UserTagCardArea
                    tag={tag}
                    text={targetRef}
                    onInteraction={() => {
                      sendEvent("Interact with Themi", {
                        themiId: video?.uuid,
                        feature: "user-tagging",
                      });
                    }}
                  />
                </ElementUserTagPortal>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
