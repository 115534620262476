import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { sendEvent } from "../helpers/tracking";
import { PlayerProvider } from "../VideoPlayer/contexts/PlayerContext";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import { PlaybackProvider } from "./contexts/PlaybackContext";

export function EmbedPreview() {
  const params = useParams<{
    videoId: string;
  }>();

  useEffect(() => {
    sendEvent("View Themi", {
      themiId: params.videoId,
    });
  }, [params.videoId]);

  return (
    <PlayerProvider videoId={params.videoId}>
      {(video) => (
        <PlaybackProvider video={video} autoPause>
          <VideoPlayer />
        </PlaybackProvider>
      )}
    </PlayerProvider>
  );
}
