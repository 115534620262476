import { useEffect } from "react";
import { createPortal } from "react-dom";

export function ElementUserTagPortal(props: {
  children?: JSX.Element | string;
}) {
  const userTagCardContainer = document.createElement("div");
  useEffect(() => {
    document.body.appendChild(userTagCardContainer);
    return () => {
      document.body.removeChild(userTagCardContainer);
    };
  }, [userTagCardContainer]);

  return createPortal(props.children, userTagCardContainer);
}
