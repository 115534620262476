import React from "react";
import { css } from "styled-components/macro";
import { SEO } from "../components/SEO";
import { getVideos } from "../actions/themi/getVideos";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { pageLoading } from "../helpers/pageLoading";
import { PageLayout } from "../components/PageLayout";
import { Row, Headers, Column } from "../helpers/layout";
import { Button } from "../components/Button";
// import { ReactComponent as ArchiveIcon } from "../assets/icons/Archive.svg";
import { ReactComponent as ArrowIcon } from "../assets/icons/Arrow.svg";
import { useNewFetch } from "../useAPI";
import { ThemiLongCard } from "../components/ThemiLongCard";
import { ThemiSquareCard } from "../components/ThemiSquareCard";
import { useTranslation } from "react-i18next";
import { FeedbackWidget } from "../components/FeedbackWidget";

export function MyThemiDashboardPage(props: {}) {
  const history = useHistory();
  const { t } = useTranslation();

  const {
    data: allVideos,
    error: allVideosError,
    refetch: refetchVideos,
  } = useNewFetch(getVideos, {
    limit: 3,
  });

  const { data: sharedVideos, error: sharedVideosError } = useNewFetch(
    getVideos,
    {
      status: "SHARED",
      limit: 3,
    }
  );

  if (!allVideos || allVideosError) {
    return pageLoading(allVideosError);
  }
  if (!sharedVideos || sharedVideosError) {
    return pageLoading(sharedVideosError);
  }

  return (
    <PageLayout>
      <SEO>
        <title>My Themi's Dashboard</title>
      </SEO>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Link to="/">
          <ArrowIcon />
        </Link>
        <Link
          to="/"
          css={css`
            font-size: 20px;
            font-weight: 600;
            letter-spacing: -0.05em;
            margin-left: 20px;
          `}
        >
          {t("myThemi.dashboard.button.back")}
        </Link>
      </div>

      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 50px;
        `}
      >
        <Headers.H4
          css={css`
            font-size: 20px;
            font-weight: 500;
          `}
        >
          {t("myThemi.dashboard.title.themi")}
        </Headers.H4>
        <div>
          {/* <Button
            icon={<ArchiveIcon />}
            css={css`
              background-color: transparent;
              color: #cdcdcd;
              font-size: 20px;
              font-weight: 400;
            `}
            hoverStyles="none"
            onClick={() => {}}
          >
            Archive
          </Button> */}
          <Button
            css={css`
              background-color: transparent;
              border: 1px solid #000;
              color: #000;
              font-size: 20px;
              min-width: 6.5em;
              padding: 6px 20px;
              font-weight: 600;
              ${allVideos.results.length === 0 &&
              css`
                color: #cdcdcd;
                border: 1px solid #cdcdcd;
                pointer-events: none;
              `}
            `}
            hoverStyles="none"
            onClick={() => {
              history.push("/themi/all/");
            }}
          >
            {t("myThemi.dashboard.button.viewall")}
          </Button>
        </div>
      </div>

      <Row align="center" justify="center">
        <div
          css={css`
            display: flex;
            flex-wrap: row wrap;
            width: 100%;
            margin-top: 17px;
          `}
        >
          {allVideos.results.length > 0 ? (
            allVideos.results.map((video) => {
              return (
                <ThemiSquareCard
                  video={video}
                  key={video.uuid}
                  onArchive={refetchVideos}
                />
              );
            })
          ) : (
            <Headers.H4
              css={css`
                color: #cdcdcd;
                font-size: 25px;
                line-height: 32px;
                letter-spacing: -0.04em;
              `}
            >
              {t("myThemi.dashboard.draftVideos.empty")}
            </Headers.H4>
          )}
        </div>
      </Row>

      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: ${allVideos.results.length === 0 ? "185px" : "50px"};
        `}
      >
        <Headers.H4
          css={css`
            font-size: 20px;
            font-weight: 500;
          `}
        >
          {t("myThemi.dashboard.title.shared-themi")}
        </Headers.H4>
        <div>
          {/* {allVideos.results.length > 0 && (
            <Button
              icon={<ArchiveIcon />}
              css={css`
                background-color: transparent;
                color: #cdcdcd;
                font-size: 20px;
                font-weight: 400;
              `}
              hoverStyles="none"
              onClick={() => {}}
            >
              Archive
            </Button>
          )} */}

          <Button
            css={css`
              background-color: transparent;
              border: 1px solid #000;
              color: #000;
              font-size: 20px;
              min-width: 6.5em;
              padding: 6px 20px;
              font-weight: 600;
              ${sharedVideos.results.length === 0 &&
              css`
                color: #cdcdcd;
                border: 1px solid #cdcdcd;
                pointer-events: none;
              `}
            `}
            hoverStyles="none"
            onClick={() => {
              history.push("/themi/shared/");
            }}
          >
            {t("myThemi.dashboard.button.viewall")}
          </Button>
        </div>
      </div>
      <Column
        css={css`
          justify-content: ${sharedVideos.results.length === 0
            ? "center"
            : "flex-start"};
          align-items: ${sharedVideos.results.length === 0
            ? "center"
            : "flex-start"};
        `}
      >
        <div
          css={css`
            width: 100%;
            max-width: 607px;
            margin-top: 10px;

            ${sharedVideos.results.length === 0 &&
            css`
              max-width: 809px;
              text-align: center;
              align-self: center;
            `}
          `}
        >
          {sharedVideos.results.length > 0 ? (
            sharedVideos.results.map((video) => {
              return (
                <ThemiLongCard
                  video={video}
                  key={video.uuid}
                  onArchive={refetchVideos}
                />
              );
            })
          ) : (
            <Headers.H4
              css={css`
                color: #cdcdcd;
                align-self: center;
                font-size: 25px;
                line-height: 32px;
                letter-spacing: -0.04em;
              `}
            >
              {t("myThemi.dashboard.sharedVideos.empty")}
            </Headers.H4>
          )}
        </div>
      </Column>
      <FeedbackWidget />
    </PageLayout>
  );
}
