import { authorised } from "../../request";
import { UserProfile } from "../../types/UserProfile";

export async function getProfile() {
  const API = authorised();

  return await API.request<UserProfile>({
    method: "GET",
    url: `/api/studio/me/`,
  });
}
