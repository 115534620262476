import React from "react";
import { css } from "styled-components/macro";
import { Spacing } from "../helpers/layout";
import { LongDashedSeparator } from "./Separator";

export function DangerZone(props: {
  title?: string;
  subTitle?: string;
  className?: string;
  children?: React.ReactNode;
}) {
  const { title, subTitle, children } = props;

  return (
    <div className={props.className}>
      <LongDashedSeparator />

      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            margin: 0 auto;
            width: 100%;
            margin-top: 30px;
            margin-bottom: 10px;
          `}
        >
          {title && (
            <div
              css={css`
                font-weight: bold;
                margin-bottom: ${Spacing.l};
                font-size: 1.1rem;
              `}
            >
              {title}
            </div>
          )}
          {subTitle && (
            <div
              css={css`
                margin-bottom: ${Spacing.xl};
              `}
            >
              {subTitle}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
