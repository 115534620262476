import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import deLocale from "./helpers/localization/de";
import enLocale from "./helpers/localization/en";
import { setLocale } from "yup";

if (process.env.REACT_APP_LANGUAGE === "de") {
  setLocale(deLocale);
} else {
  setLocale(enLocale);
}

export const THEMIS_LANGUAGE_KEY = "themis-language";

const language =
  localStorage.getItem(THEMIS_LANGUAGE_KEY) || process.env.REACT_APP_LANGUAGE;

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: process.env.REACT_APP_LANGUAGE,
    lng: language,
    debug: true,
    ns: "translation",
    defaultNS: "translation",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
