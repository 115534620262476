import { DateTime } from "luxon";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Comment } from "../../../types/Comment";
import { Headers } from "../../helpers/headers";
import { Avatar } from "../Avatar";
import { useComments } from "../../contexts/CommentsContext";
import { usePlayback } from "../../contexts/PlaybackContext";
import { Dropdown, DropdownItem } from "../../../components/Dropdown";
import { useAuth } from "../../../contexts/UserContext";
import { Button } from "../../../components/Button";
import { ReactComponent as ThreeDotsIcon } from "../../../assets/icons/ThreeDots.svg";

type CommentSidebarCardProps = {
  comment: Pick<
    Comment,
    "id" | "author" | "content" | "created_at" | "is_read" | "scene_id"
  >;
  setSelectedCommentId: (id: number) => void;
  setDeleteModal: () => void;
};

export function CommentSidebarCard(props: CommentSidebarCardProps) {
  const { userprofile } = useAuth();
  const { setExpandedComment, closeComments } = useComments();
  const { scenes, setCurrentTime, activeScene } = usePlayback();
  const { t } = useTranslation();

  const onClick = () => {
    if (!activeScene) return;

    if (props.comment.scene_id !== activeScene.id) {
      const scene = scenes.find((scene) => scene.id === props.comment.scene_id);

      if (scene) {
        setCurrentTime(scene.start_time);
      }
    }
    setExpandedComment(props.comment.id);

    closeComments();
  };

  const dropdownOptions = [
    {
      label: t("comment.delete"),
      onClick: () => {
        props.setSelectedCommentId(props.comment.id);
        props.setDeleteModal();
      },
    },
  ];

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        cursor: default;
        z-index: 9999;

        :hover {
          background-color: #f2f2f2;
        }
      `}
      onClick={onClick}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 26px;
          margin-left: 20px;
        `}
      >
        <Avatar
          url={props.comment.author.avatar?.image}
          name={`${props.comment.author.first_name} ${props.comment.author.last_name}`}
          className="avatar"
          css={css`
            position: relative;
          `}
          border
          size={29}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-left: 8px;
          `}
        >
          <Headers.H4
            css={css`
              font-weight: 500;
              line-height: 21px;
            `}
          >
            {props.comment.author.first_name +
              " " +
              props.comment.author.last_name}{" "}
          </Headers.H4>
          <span
            css={css`
              font-size: 14px;
              color: #8e8e8e;
            `}
          >
            {DateTime.fromISO(props.comment.created_at).toFormat("dd/MM/yyyy")}
          </span>
        </div>
        {userprofile?.id === props.comment.author.id && (
          <div
            css={css`
              margin: 0 7px 0 auto;
            `}
          >
            <Dropdown
              options={dropdownOptions.map((option, key) => {
                return (
                  <DropdownItem key={key} onClick={option.onClick}>
                    {option.label}
                  </DropdownItem>
                );
              })}
            >
              <Button
                css={css`
                  min-width: 2em;
                  margin: 0;
                  padding: 0;
                  background-color: transparent;
                `}
                hoverStyles="none"
              >
                <ThreeDotsIcon
                  css={css`
                    cursor: pointer;
                    align-items: center;
                  `}
                />
              </Button>
            </Dropdown>
          </div>
        )}
      </div>
      <div
        css={css`
          display: flex;
          margin: 12px 20px 28px 20px;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
      >
        <span
          css={css`
            font-size: 16px;
          `}
        >
          {props.comment.content}
        </span>
      </div>
      <div
        css={css`
          border-bottom: 1px solid #d0d0d0;
          margin: 0 12px;
        `}
      ></div>
    </div>
  );
}
