import { useEffect, useState } from "react";
import {
  CanvasSize,
  DraggingPosition,
  useEditor,
} from "../contexts/EditorContext";
import { usePlayback } from "../contexts/PlaybackContext";
import {
  calculateProportionalSize,
  calculateReverseProportionalSize,
} from "../helpers/renderer";

type Gridline = {
  left: number | null;
  top: number | null;
};

function isEqual(a: number | null, b: number | null) {
  if (a === null || b === null) return false;

  return Math.abs(Math.floor(a) - Math.floor(b)) < 2;
}

function getDraggingSize(
  draggingPosition: DraggingPosition,
  canvasSize: CanvasSize
) {
  const { boundaries } = draggingPosition;

  const size = boundaries.reduce(
    (acc, boundary) => {
      return {
        maxLeft: Math.max(acc.maxLeft, boundary.x),
        maxTop: Math.max(acc.maxTop, boundary.y),
        minLeft: Math.min(acc.minLeft, boundary.x),
        minTop: Math.min(acc.minTop, boundary.y),
      };
    },
    {
      maxLeft: 0,
      maxTop: 0,
      minLeft: canvasSize.width,
      minTop: canvasSize.height,
    }
  );

  return {
    width: size.maxLeft - size.minLeft,
    height: size.maxTop - size.minTop,
    left: size.minLeft,
    top: size.minTop,
  };
}

export function Gridlines() {
  const { activeScene } = usePlayback();
  const { draggingPosition, canvasSize } = useEditor();

  const [gridlines, setGridlines] = useState<Gridline[]>([]);

  useEffect(() => {
    if (!activeScene) return;

    const gridlines: Gridline[] = [];

    if (!draggingPosition) {
      setGridlines(gridlines);
      return;
    }

    const { elements } = activeScene;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      if (element.id === draggingPosition.elementId) continue;

      const { left, top, width, height } = element.states[0];

      for (let j = 0; j < draggingPosition.boundaries.length; j++) {
        const boundary = draggingPosition.boundaries[j];

        let newGridline: Gridline | null = null;

        if (isEqual(left, boundary.x)) {
          newGridline = { left: left, top: null };
        }

        if (isEqual(top, boundary.y)) {
          newGridline = { left: null, top: top };
        }

        if (isEqual(left + width, boundary.x)) {
          newGridline = { left: left + width, top: null };
        }

        if (isEqual(top + height, boundary.y)) {
          newGridline = { left: null, top: top + height };
        }

        if (newGridline) {
          const existingGridline = gridlines.find(
            (gridline) =>
              gridline.left === newGridline!.left &&
              gridline.top === newGridline!.top
          );

          if (!existingGridline) {
            gridlines.push(newGridline);
          }
        }
      }
    }

    const size = getDraggingSize(draggingPosition, canvasSize);
    const normalizedCanvasSize = {
      width: calculateReverseProportionalSize(
        canvasSize.width,
        canvasSize.width
      ),
      height: calculateReverseProportionalSize(
        canvasSize.height,
        canvasSize.width
      ),
    };

    if (isEqual(size.left, (normalizedCanvasSize.width - size.width) / 2)) {
      gridlines.push({ left: normalizedCanvasSize.width / 2, top: null });
    }

    if (isEqual(size.top, (normalizedCanvasSize.height - size.height) / 2)) {
      gridlines.push({ left: null, top: normalizedCanvasSize.height / 2 });
    }

    setGridlines(gridlines);
  }, [activeScene, draggingPosition, canvasSize]);

  return (
    <>
      {gridlines.map((gridline, index) => {
        const gridlineType = gridline.left ? "vertical" : "horizontal";

        return (
          <div
            key={index}
            style={{
              position: "absolute",
              left: gridline.left
                ? calculateProportionalSize(gridline.left, canvasSize.width)
                : 0,
              top: gridline.top
                ? calculateProportionalSize(gridline.top, canvasSize.width)
                : 0,
              width: gridlineType === "vertical" ? 1 : "100%",
              height: gridlineType === "horizontal" ? 1 : "100%",
              pointerEvents: "none",
              background: "red",
            }}
          />
        );
      })}
    </>
  );
}
