import { useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { sendEvent } from "./tracking";

export function RouterWrapper(props: RouteComponentProps & { children: any }) {
  useEffect(() => {
    const unlisten = props.history.listen((location: any) => {
      sendEvent("Page View");
    });
    return () => unlisten();
  });

  return props.children;
}

export default withRouter(RouterWrapper);
