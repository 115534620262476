import { authorised } from "../../request";

export async function getVotes(themiId: string, pollId: string) {
  const API = authorised();

  return await API.request<{
    votes: string[];
  }>({
    method: "GET",
    url: `/api/studio/themis/${themiId}/polls/${pollId}/votes/`,
  });
}
