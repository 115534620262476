import { authorised } from "../../request";
import { Video, VideoSchema } from "../types/Video";

export async function createVideo(input: {
  name: string;
  initial_content?: VideoSchema;
}) {
  const API = authorised();

  return API.request<Video>({
    method: "POST",
    url: `/api/studio/themis/`,
    data: input,
  });
}
