import { archiveVideo } from "../actions/themi/archiveVideo";
import { customToast } from "../components/customToast";
import { Video, VideoPublishStatus } from "../VideoEditor/types/Video";
import { serverErrorHandler } from "./serverErrorHandler";

export  async function handleArchiveRecover(video:Video, onArchive: () => Promise<void>,t:any){
        try {
          await archiveVideo(video.uuid, video.archived_at === null? VideoPublishStatus.ARCHIVED : VideoPublishStatus.PUBLISHED);
          await onArchive();
        } catch (error) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(error),
            })
          );
        
      }
    
}