import Dexie, { Table } from "dexie";
import { Video } from "./types/Video";

export type ChangeActionType =
  | "create-scene"
  | "update-scene"
  | "delete-scene"
  | "reorder-scene"
  | "create-element"
  | "create-elements"
  | "update-element"
  | "update-elements"
  | "delete-element"
  | "delete-elements";

export interface VideoStateUpdate {
  id?: number;
  event_id: string;
  remote_id: number | null;
  update_group_id: string | null;
  video_id: string;
  timestamp: number;
  user_id: number;
  action: ChangeActionType;
  element_id?: string | null;
  content: any;
  fake?: boolean;
}

export type VideoStateUpdateDraft = Omit<VideoStateUpdate, "event_id">;

export interface VideoTable {
  id?: number;
  uuid: string;
  content: Video;
}

export class ThemisDexie extends Dexie {
  video_state_updates!: Table<VideoStateUpdate>;
  videos!: Table<VideoTable>;

  constructor() {
    super("themis");
    this.version(1).stores({
      video_state_updates:
        "++id, video_id, timestamp, user_id, element_id, remote_id",
      videos: "++id, uuid",
    });
    this.version(2).stores({
      video_state_updates:
        "++id, video_id, timestamp, user_id, element_id, remote_id, event_id",
      videos: "++id, uuid",
    });
  }
}

export const db = new ThemisDexie();
