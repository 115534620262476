import { authorised } from "../../request";
import { Video } from "../types/Video";

export async function publishVideo(videoId: string, change_id: number | string | null) {
  const API = authorised();

  const response = await API.request<Video>({
    method: "POST",
    url: `/api/studio/themis/${videoId}/publish/`,
    data: {
      change_id
    }
  });

  return response.data;
}
