import { authorised } from "../../request";
import { Comment, CommenPutRequest } from "../../types/Comment";

export async function updateComment(
  comment: CommenPutRequest,
  themi_id: string
) {
  const API = authorised();
  const response = await API.request<Comment>({
    method: "PUT",
    url: `/api/studio/themis/${themi_id}/comments/${comment.id}/`,
    data: { comment: comment.comment },
  });

  return response.data;
}
