import React from "react";
import { Field, FieldProps } from "formik";
import { Image, ImageType } from "../../types/Image";
import { ImageUpload } from "../../components/ImageUpload";

export function ImageUploadField(props: {
  id?: string;
  name: string;
  label?: string;
  className?: string;
  disableLibrary?: boolean;
  type?: ImageType;
  validate?: (value: string) => Promise<void | string>;
  uploadFn: (args: {
    file: File;
    title?: string | null;
    type?: ImageType;
    onUploadProgress?: (progressEvent: any) => void;
  }) => Promise<Image | string>;
}) {
  return (
    <Field name={props.name} validate={props.validate}>
      {({ field, form }: FieldProps) => {
        const image: Image | string | null = field.value;

        return (
          <ImageUpload
            id={props.id}
            image={image}
            uploadFn={props.uploadFn}
            label={props.label}
            onChange={(img) => form.setFieldValue(props.name, img)}
            className={props.className}
            disableLibrary={props.disableLibrary}
            type={props.type}
          />
        );
      }}
    </Field>
  );
}
