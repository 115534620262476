import React from "react";
import { css } from "styled-components/macro";
import { Image } from "../types/Image";
import { theme } from "../themes/variables";
import { ReactComponent as ReadIcon } from "../assets/icons/Read.svg";

export function ImagesRow(props: {
  images: Image[];
  className?: string;
  value?: Image | null;
  onSelect?: (image: Image) => void;
  onDoubleClick?: (image: Image) => void;
}) {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 15px;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 290px);
        > div {
          margin: auto;
        }
      `}
    >
      {props.images.map((image) => {
        return (
          <div
            key={image.uuid}
            css={css`
              width: 180px;
              height: 180px;
              border: 2px solid
                ${image.uuid === props.value?.uuid
                  ? theme.colors.primary
                  : theme.colors.gray2};
              border-radius: 8px;
              position: relative;
              cursor: pointer;
              overflow: hidden;
            `}
            className={props.className}
            onClick={() => {
              props.onSelect && props.onSelect(image);
            }}
            onDoubleClick={() => {
              props.onDoubleClick && props.onDoubleClick(image);
            }}
          >
            {image.uuid === props.value?.uuid && (
              <div
                css={css`
                  width: 24px;
                  height: 24px;
                  position: absolute;
                  bottom: 19px;
                  right: 19px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transform: translate(50%, 50%);
                  border-radius: 50%;
                  background: ${theme.colors.primary};
                `}
              >
                <ReadIcon color={theme.colors.white} />
              </div>
            )}

            <img
              src={image.image}
              alt={image.upload_name}
              title={image.upload_name}
              css={css`
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                height: 100%;
                background: ${theme.colors.gray1};
                object-fit: contain;
                position: relative;
                left: 0.5px;
              `}
            />
          </div>
        );
      })}
    </div>
  );
}
