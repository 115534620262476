import { VideoElementStar, VideoElementState } from "../../types/Video";
import { css } from "styled-components/macro";
import { calculateProportionalSize } from "../../helpers/renderer";
import { useEditor } from "../../contexts/EditorContext";

export function ElementStarRenderer(props: {
  element: VideoElementStar;
  state: VideoElementState;
}) {
  const { config } = props.element;
  const width = props.state.width;
  const height = props.state.height;
  const { canvasSize } = useEditor();
  const strokeSize = calculateProportionalSize(
    config.strokeSize,
    canvasSize.width
  );

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
      `}
    >
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill={config.fillColor}
        fillOpacity={config.fillOpacity / 100}
        stroke={config.strokeColor}
        strokeWidth={strokeSize}
        strokeOpacity={config.strokeOpacity / 100}
      >
        <polygon
          points={`
            ${strokeSize * 2}, ${height * 0.38}
            ${width * 0.38}, ${height * 0.38}
            ${width * 0.5}, ${strokeSize}
            ${width * 0.62}, ${height * 0.38}
            ${width - strokeSize * 2}, ${height * 0.38}
            ${width * 0.68}, ${height * 0.61}
            ${width * 0.81}, ${height - strokeSize}
            ${width * 0.5}, ${height * 0.79}
            ${width * 0.19}, ${height - strokeSize}
            ${width * 0.32}, ${height * 0.61}
          `}
        />
      </svg>
    </div>
  );
}
