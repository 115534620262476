import { AxiosResponse } from "axios";
import { useState, useEffect, useRef, useCallback } from "react";
import { useNewFetch } from "../useAPI";
import queryString from "query-string";

type DefaultT = {
  count: number;
  next: null | string;
  previous: null | string;
};

export function useInfiniteScroll<T extends DefaultT, Q>(
  getFunction: (args: {
    limit: number;
    offset: number;
  }) => Promise<AxiosResponse<T>>,
  options: {
    limit?: number;
    internalArgs?: Omit<Q, "limit" | "offset">;
    containerRef: React.RefObject<Element> | null;
  }
) {
  const limit = options?.limit || 50;
  const [offset, setOffset] = useState(0);
  const observer = useRef<IntersectionObserver>();

  const { data, error, refetch, isLoading, setData } = useNewFetch(
    getFunction,
    {
      ...options?.internalArgs,
      limit: limit,
      offset: offset,
    }
  );

  const onLoadMoreData = useCallback(() => {
    if (!data || !data.next) {
      return;
    }

    const queryArgs = queryString.parse(data.next) as Q & {
      offset: number;
    };

    setOffset(queryArgs.offset);
  }, [data, setOffset]);

  useEffect(() => {
    if (!data || !data.next) {
      return;
    }

    if (!options.containerRef) {
      return;
    }

    const target = options.containerRef.current;

    if (!target) return;

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onLoadMoreData();
        }
      },
      { rootMargin: "20px" }
    );

    observer.current?.observe(target);

    return () => observer.current?.unobserve(target);
  }, [data, onLoadMoreData, options.containerRef]);

  const onClear = useCallback(() => {
    setOffset(0);
  }, [setOffset]);

  return {
    response: {
      error,
      isLoading,
      data,
      refetch,
      setData,
    },
    data: {
      onClear,
      count: data ? Math.ceil(data.count / limit) : 0,
    },
  };
}
