import React, { useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { theme } from "../../../themes/variables";
import { Image, ImageType } from "../../types/Image";
import { Button } from "../../components/Button";
import { customToast } from "../../../components/customToast";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { Loader } from "../../components/Loader";

const checkIsString = (p: any): p is string => {
  return p && typeof p === "string";
};

export function ImageUpload(props: {
  id?: string;
  label?: string;
  className?: string;
  disableLibrary?: boolean;
  image?: Image | string | null;
  type?: ImageType;
  onChange: (image: Image | string | null) => void;
  uploadFn: (args: {
    file: File;
    title?: string | null;
    type?: ImageType;
    onUploadProgress?: (progressEvent: any) => void;
  }) => Promise<Image | string>;
}) {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div
      id={props.id}
      className={props.className}
      css={css`
        margin-bottom: 32px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            width: 47px;
            height: 47px;
          `}
        >
          {props.image ? (
            <div
              css={css`
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: url(${checkIsString(props.image)
                  ? props.image
                  : props.image.image});
                background-size: cover;
              `}
            ></div>
          ) : (
            <div
              css={css`
                width: 100%;
                height: 100%;
                background: ${theme.colors.gray2};
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;
              `}
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.click();
                }
              }}
            >
              {uploading && (
                <div>
                  <Loader />
                </div>
              )}
            </div>
          )}
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            align-items: flex-start;
          `}
        >
          <h5
            css={css`
              margin-bottom: 5px;
            `}
          >
            {t("upload-photo.label")}
          </h5>
          <Button
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.click();
              }
            }}
            css={css`
              padding: 5px 10px;
              color: #fff;
              font-size: 12px;
            `}
          >
            {t("upload-photo.upload")}
          </Button>

          {props.image && (
            <button
              css={css`
                background: none;
                border: none;
                padding: 0;
                margin: 0;
                text-decoration: underline;
              `}
              onClick={() => props.onChange(null)}
            >
              {t("actions.delete")}
            </button>
          )}
        </div>
      </div>
      <input
        css={css`
          display: none;
        `}
        ref={inputRef}
        type="file"
        accept=".jpg,.png,.svg,.jpeg"
        onChange={async (e) => {
          try {
            setUploading(true);

            const file = e.target.files?.[0];

            if (!file) return;

            const image = await props.uploadFn({
              file: file,
              title: file.name,
              type: props.type,
            });

            if (image) {
              props.onChange(image);
            }
          } catch (error: any) {
            customToast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setUploading(false);
          }
        }}
      />
    </div>
  );
}
