import { authorised } from "../../request";

export async function changePassword(data: {
  password: string;
  new_password: string;
  confirm_new_password: string;
}) {
  const API = authorised();

  return await API.request<{
    token: string;
  }>({
    method: "PUT",
    url: `/api/studio/me/change-password/`,
    data
  });
}
