import { authorised } from "../../request";
import { Comment, CommentPostRequest } from "../../types/Comment";

export async function createComment(
  comment: CommentPostRequest,
  themi_id: string
) {
  const API = authorised();
  const response = await API.request<Comment>({
    method: "POST",
    url: `/api/studio/themis/${themi_id}/comments/`,
    data: comment,
  });

  return response.data;
}
