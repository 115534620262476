import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { css } from "styled-components/macro";
import { Trash2 } from "react-feather";
import { Image } from "../types/Image";
import { useTranslation } from "react-i18next";
import { Loader } from "./Loader";
import { Button } from "./Button";
import { theme } from "../themes/variables";

export type DroppedImage = {
  preview: string;
  file: File;
};

export function DropImage(props: {
  onImageDropped?: (image: DroppedImage) => void;
  onImageDeleted?: () => void;
  initialMessage?: React.ReactNode | React.ReactNode[];
  dragActiveMessage?: React.ReactNode | React.ReactNode[];
  loadingMessage?: React.ReactNode | React.ReactNode[];
  className?: string;
  immediatelyUpload?: boolean;
  selectedImage?: DroppedImage | null;
  onClickPreview?: () => void;
  uploadFn?: (args: {
    file: File;
    title?: string | null;
    onUploadProgress?: (progressEvent: any) => void;
  }) => Promise<Image>;
}) {
  const [preview, setPreview] = useState<string | null>(null);

  const [uploading, setUploading] = useState(false);

  const { t } = useTranslation();

  const {
    onImageDropped = () => {},
    onImageDeleted = () => {},
    initialMessage = t("actions.drop-files"),
    loadingMessage = <Loader />,
    className,
  } = props;

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      const file = acceptedFiles[0];

      if (file) {
        setUploading(true);

        const tmpImage = URL.createObjectURL(file);
        onImageDropped({
          preview: tmpImage,
          file,
        });
        setPreview(tmpImage);

        setUploading(false);
      }
    },
    [onImageDropped]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      {preview ? (
        <div
          onClick={props.onClickPreview}
          css={css`
            position: relative;
            width: 100%;
            height: 170px;
            border: 1px solid transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          `}
        >
          <img
            src={preview}
            alt={t("dashboard.templateData.preview")}
            css={css`
              max-width: 100%;
              max-height: 100%;
              width: auto;
              height: auto;
              border-radius: 10px;
              ${props.selectedImage
                ? `border: 2px solid ${theme.colors.primary};`
                : `border: 2px solid ${theme.colors.transparent};`}
            `}
          />

          <Button
            css={css`
              position: absolute;
              top: 0;
              right: 0;
              min-width: 2rem;
              min-height: 2rem;
            `}
            flat
            background={theme.colors.transparent}
            color={theme.colors.dark}
            hoverStyles={`color: ${theme.colors.error}; background: ${theme.colors.transparent};`}
            onClick={() => {
              setPreview(null);
              onImageDeleted();
            }}
          >
            <Trash2 size={20} />
          </Button>
        </div>
      ) : (
        <div
          {...getRootProps()}
          css={css`
            height: 170px;
            width: 100%;
            border: 1px dashed
              ${isDragActive ? theme.colors.primary : theme.colors.gray2};
            background: ${theme.colors.white};
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            ${isDragActive ? `background: ${theme.colors.primaryLighter};` : ""}
          `}
          className={className}
        >
          <input {...getInputProps()} accept=".jpg,.png,.svg,.jpeg" />
          {uploading ? (
            <div>{loadingMessage}</div>
          ) : (
            <div>{initialMessage}</div>
          )}
        </div>
      )}
    </div>
  );
}
