import React, { useState } from "react";
import { Comment } from "../../../types/Comment";
import { CommentCard } from ".";
import { css } from "styled-components/macro";
import { theme } from "../../../themes/variables";
import { Button } from "../../../components/Button";
import { Avatar } from "../../../VideoEditor/components/Avatar";
import { ReactComponent as Expand } from "../../assets/icons/Expand.svg";
import { useTranslation } from "react-i18next";

type CommentsThreadProps = {
  comments: Comment[];
  setSelectedCommentId: (id: number) => void;
  setDeleteModal: () => void;
  isReplyActive: boolean;
  setReply: (comment: Comment) => void;
};

export function CommentsThread(props: CommentsThreadProps) {
  const { t } = useTranslation();
  const [expandedThread, setExpandedThread] = useState<boolean>(false);
  const rootComment = props.comments[0];
  const repliedComments =
    props.comments.length >= 1 ? props.comments.slice(1) : [];

  return (
    <div
      css={css`
        margin-bottom: 42px;
      `}
    >
      <CommentCard
        comment={rootComment}
        setSelectedCommentId={(id: number) => props.setSelectedCommentId(id)}
        setDeleteModal={props.setDeleteModal}
      />
      <div>
        <Button
          css={css`
            cursor: pointer;
            background: ${theme.colors.white};
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 90px;
            padding: 3px 0;

            color: ${props.isReplyActive ? "#e95b2e" : "#8e8e8e"};
            border: 1px solid ${props.isReplyActive ? "#e95b2e" : "#8e8e8e"};
            margin-left: 65px;
            margin-top: 10px;
          `}
          hoverStyles={`color: #E95B2E; border-color: #E95B2E;`}
          onClick={() => props.setReply(rootComment)}
        >
          {t("comment.reply")}
        </Button>

        {repliedComments.length > 0 && (
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 65px;
              margin-top: 24px;
              cursor: pointer;
            `}
            onClick={() => setExpandedThread(!expandedThread)}
          >
            <Avatar
              border
              url={repliedComments[0].author.avatar?.image}
              className="avatar"
              css={css`
                position: relative;
              `}
              size={18}
              name={`${repliedComments[0].author.first_name} ${repliedComments[0].author.last_name}`}
            />
            <Button
              css={css`
                cursor: pointer;
                background: ${theme.colors.white};
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 70px;
                padding: 0;
                color: #e95b2e;
              `}
              hoverStyles={"none"}
            >
              {repliedComments.length} {t("comment.reply")}
            </Button>

            <Expand
              css={css`
                margin-top: 2px;
                transition: transform 0.6s ease;
                transform: ${expandedThread ? `rotate(-180deg)` : `none`};
              `}
            />
          </div>
        )}
      </div>
      {expandedThread &&
        repliedComments &&
        repliedComments.map((comment, key) => (
          <div
            key={key}
            css={css`
              margin-left: 65px;
              margin-top: 28px;
            `}
          >
            <CommentCard
              comment={comment}
              setSelectedCommentId={(id: number) =>
                props.setSelectedCommentId(id)
              }
              setDeleteModal={props.setDeleteModal}
            />
          </div>
        ))}
    </div>
  );
}
