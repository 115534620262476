import React from "react";
import { css } from "styled-components/macro";
import { Comment } from "../../../types/Comment";
import { Avatar } from "../../../VideoEditor/components/Avatar";
import { ReactComponent as ThreeDotsIcon } from "../../../assets/icons/ThreeDots.svg";
import { useAuth } from "../../../contexts/UserContext";
import { Dropdown, DropdownItem } from "../../../components/Dropdown";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { usePlayback } from "../../../VideoEditor/contexts/PlaybackContext";

type CommentCardProps = {
  comment: Comment;
  setSelectedCommentId: (id: number) => void;
  setDeleteModal: () => void;
};

export function CommentCard(props: CommentCardProps) {
  const { userprofile } = useAuth();
  const { t } = useTranslation();
  const { setCurrentTime } = usePlayback();

  const dropdownOptions = [
    {
      label: t("comment.delete"),
      onClick: () => {
        props.setSelectedCommentId(props.comment.id);
        props.setDeleteModal();
      },
    },
  ];
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        width: 100%;
      `}
    >
      <div
        css={css`
          width: 50px;
          height: 50px;
        `}
      >
        <Avatar
          border
          url={props.comment.author.avatar?.image}
          className="avatar"
          css={css`
            position: relative;
          `}
          size={50}
          name={`${props.comment.author.first_name} ${props.comment.author.last_name}`}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-left: 15px;
        `}
      >
        <span
          css={css`
            margin: 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 120%;
          `}
        >{`${props.comment.author.first_name} ${props.comment.author.last_name}`}</span>
        {props.comment.timeline !== undefined &&
          props.comment.timeline !== null && (
            <span
              css={css`
                font-size: 14px;
                color: #8e8e8e;
                cursor: pointer;
              `}
              onClick={() => {
                if (props.comment.timeline)
                  setCurrentTime(props.comment.timeline);
              }}
            >
              {`${t("comment.comment_left")} ${DateTime.fromMillis(
                props.comment.timeline
              ).toFormat("mm:ss")}`}
            </span>
          )}
        <span
          css={css`
            font-size: 16px;
            margin-top: 8px;
          `}
        >{`${props.comment.content}`}</span>
      </div>
      {userprofile?.id === props.comment.author.id && (
        <div
          css={css`
            margin-right: 10px;
          `}
        >
          <Dropdown
            options={dropdownOptions.map((option, key) => {
              return (
                <DropdownItem key={key} onClick={option.onClick}>
                  {option.label}
                </DropdownItem>
              );
            })}
          >
            <ThreeDotsIcon
              css={css`
                cursor: pointer;
                align-items: center;
              `}
            />
          </Dropdown>
        </div>
      )}
    </div>
  );
}
