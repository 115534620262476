import { useState } from "react";
import { css } from "styled-components/macro";
import { Giphy } from "./Giphy";
import { ImageSelector } from "./ImageSelector";
import { ReactComponent as UploadImageIcon } from "../assets/icons/UploadImage.svg";
import { ReactComponent as LeftArrowIcon } from "../assets/icons/LeftArrow.svg";
import { Headers } from "../helpers/headers";
import { Button } from "./Button";
import { useTranslation } from "react-i18next";

export function ImagesSidebar() {
  const { t } = useTranslation();
  const [mode, setMode] = useState<"giphy" | "images" | "all">("all");

  return (
    <div
      css={css`
        height: 100%;
        min-height: 0;
      `}
    >
      {mode === "all" ? (
        <>
          <div
            css={css`
              display: flex;
              margin-bottom: 10px;
            `}
          >
            <UploadImageIcon
              css={css`
                align-self: center;
              `}
            />
            <Headers.H3
              css={css`
                margin-left: 11px;
              `}
            >
              Upload media
            </Headers.H3>
          </div>

          <ImageSelector
            onShowMore={() => {
              setMode("images");
            }}
            limit={4}
            preview
          />
          <hr
            css={css`
              background-color: #d9d9d9;
              height: 1px;
              border: 0;
              margin: 25px 0 !important;
            `}
          />
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
            `}
          >
            <Headers.H3>Giphy</Headers.H3>
            <button
              css={css`
                border: none;
                background-color: transparent;
                align-self: flex-end;
                margin-bottom: 11px;
                text-decoration: underline;
                cursor: pointer;
                font-size: 14px;
              `}
              onClick={() => {
                setMode("giphy");
              }}
            >
              {t("media.sidebar.button.showMore")}
            </button>
          </div>

          <Giphy limit={4} enableSearch />
        </>
      ) : mode === "giphy" ? (
        <>
          <Button
            icon={
              <LeftArrowIcon
                css={css`
                  width: 11px;
                  height: 10px;
                  align-self: center;
                  margin-right: -7px;
                `}
              />
            }
            css={css`
              background-color: transparent;
              margin-bottom: 10px;
              padding: 0;
              min-width: 2em;
              :hover {
                background-color: transparent;
              }
            `}
            onClick={() => {
              setMode("all");
            }}
          >
            {t("media.sidebar.button.back")}
          </Button>
          <Giphy enableSearch />
        </>
      ) : mode === "images" ? (
        <>
          <Button
            icon={
              <LeftArrowIcon
                css={css`
                  width: 11px;
                  height: 10px;
                  align-self: center;
                  margin-right: -7px;
                `}
              />
            }
            css={css`
              background-color: transparent;
              margin-bottom: 10px;
              padding: 0;
              min-width: 2em;
              :hover {
                background-color: transparent;
              }
            `}
            onClick={() => {
              setMode("all");
            }}
          >
            {t("media.sidebar.button.back")}
          </Button>
          <ImageSelector
            enableSearch
            onShowMore={() => {
              setMode("images");
            }}
            limit={20}
          />
        </>
      ) : null}
    </div>
  );
}
