import {
  VideoElementPollChoice,
  VideoElementPoll,
  VideoElementState,
} from "../../types/Video";
import { css } from "styled-components/macro";
import { useCallback, useState } from "react";
import { debounce } from "lodash";
import { useStorage } from "../../contexts/StorageContext";
import striptags from "striptags";
import { cloneDeep } from "lodash";

import { v4 as uuid } from "uuid";
import { calculateProportionalSize } from "../../helpers/renderer";
import { useEditor } from "../../contexts/EditorContext";
import { ContentEditable } from "../../components/ContentEditable";
import TickIcon from "../../assets/icons/Tick.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/Cancel.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlusIcon } from "../../assets/icons/Plus_CreateScene.svg";

export function ElementPollRenderer(props: {
  element: VideoElementPoll;
  state: VideoElementState;
}) {
  const { api } = useStorage();
  const { canvasSize } = useEditor();
  const { t } = useTranslation();
  const [title, setTitle] = useState(props.element.title || t("poll.title"));
  const [subtitle, setSubtitle] = useState(
    props.element.subtitle || t("poll.description")
  );
  const [choices, setChoices] = useState<VideoElementPollChoice[]>(
    props.element.choices || [
      {
        id: uuid(),
        text: t("poll.text"),
        is_correct: false,
      },
      {
        id: uuid(),
        text: t("poll.text"),
        is_correct: false,
      },
    ]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateTitle = useCallback(
    debounce((elementId: string, title: string) => {
      api.updateElement(elementId, {
        title: title,
      });
    }, 1000),
    [api]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSubtitle = useCallback(
    debounce((elementId: string, subtitle: string) => {
      api.updateElement(elementId, {
        subtitle: subtitle,
      });
    }, 1000),
    [api]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateChoices = useCallback(
    debounce((elementId: string, choices: VideoElementPollChoice[]) => {
      api.updateElement(elementId, {
        choices: choices,
      });
    }, 1000),
    [api]
  );

  const handleTitle = (event: any) => {
    const content = striptags(event.target.value, [], "");

    setTitle(content);
    updateTitle(props.element.id, content);
  };

  const handleSubtitle = (event: any) => {
    const content = striptags(event.target.value, [], "");

    setSubtitle(content);
    updateSubtitle(props.element.id, content);
  };

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
        background: #ffffff;
      `}
      style={{
        borderRadius: calculateProportionalSize(10, canvasSize.width),
        padding: calculateProportionalSize(20, canvasSize.width),
      }}
    >
      <ContentEditable
        style={{
          fontSize: calculateProportionalSize(15, canvasSize.width),
          fontWeight: 500,
        }}
        html={title}
        onChange={handleTitle}
      />
      <div
        style={{
          marginTop: calculateProportionalSize(10, canvasSize.width),
          marginBottom: calculateProportionalSize(10, canvasSize.width),
        }}
      >
        <ContentEditable
          style={{
            fontSize: calculateProportionalSize(10, canvasSize.width),
            lineHeight: "1.2",
          }}
          html={subtitle}
          onChange={handleSubtitle}
        />
        <div
          css={css`
            margin-top: ${calculateProportionalSize(10, canvasSize.width)}px;
          `}
        >
          {choices.map((choice, index) => {
            return (
              <div
                key={index}
                css={css`
                  position: relative;
                  padding-right: ${calculateProportionalSize(
                    30,
                    canvasSize.width
                  )}px;
                  &:hover .remove-option {
                    opacity: 1;
                    visibility: visible;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    background: #f2f2f2;
                    border: ${calculateProportionalSize(1, canvasSize.width)}px
                      solid #252424;
                    border-radius: ${calculateProportionalSize(
                      3,
                      canvasSize.width
                    )}px;

                    &:last-child {
                      margin-bottom: 0;
                    }
                  `}
                  style={{
                    padding: calculateProportionalSize(5, canvasSize.width),
                    marginBottom: calculateProportionalSize(
                      10,
                      canvasSize.width
                    ),
                  }}
                >
                  <div
                    css={css`
                      flex: 0 0 auto;
                      margin-right: ${calculateProportionalSize(
                        5,
                        canvasSize.width
                      )}px;
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <input
                      type="checkbox"
                      checked={choice.is_correct}
                      onChange={(e) => {
                        e.stopPropagation();

                        const newChoices = cloneDeep(choices);
                        newChoices[index].is_correct = e.target.checked;
                        setChoices(newChoices);
                        updateChoices(props.element.id, newChoices);
                      }}
                      css={css`
                        width: ${calculateProportionalSize(
                          13,
                          canvasSize.width
                        )}px;
                        height: ${calculateProportionalSize(
                          13,
                          canvasSize.width
                        )}px;
                        border-radius: 50%;
                        cursor: pointer;
                        border: ${calculateProportionalSize(
                            1,
                            canvasSize.width
                          )}px
                          solid black;
                        transition: 0.2s all linear;
                        margin-right: ${calculateProportionalSize(
                          5,
                          canvasSize.width
                        )}px;
                        outline: none;
                        appearance: none;
                        content: "";
                        background-image: url(${TickIcon});
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 60%;
                        :checked {
                          background-color: #74d048;
                        }
                      `}
                    />
                  </div>

                  <ContentEditable
                    style={{
                      fontSize: calculateProportionalSize(10, canvasSize.width),
                      outline: "none",
                      flex: "1 1 10%",
                    }}
                    html={choice.text}
                    onChange={(event) => {
                      const content = striptags(event.target.value, [], "");

                      const newChoices = cloneDeep(choices);
                      newChoices[index] = {
                        ...newChoices[index],
                        text: content,
                      };

                      setChoices(newChoices);
                      updateChoices(props.element.id, newChoices);
                    }}
                  />
                  <button
                    className="remove-option"
                    css={css`
                      position: absolute;
                      display: flex;
                      width: ${calculateProportionalSize(
                        10,
                        canvasSize.width
                      )}px;
                      height: ${calculateProportionalSize(
                        10,
                        canvasSize.width
                      )}px;
                      right: ${calculateProportionalSize(
                        10,
                        canvasSize.width
                      )}px;
                      border-radius: 50%;
                      background: #e95b2e;
                      border: none;
                      cursor: pointer;
                      font-size: ${calculateProportionalSize(
                        10,
                        canvasSize.width
                      )}px;
                      align-items: center;
                      color: #fff;
                      justify-content: center;
                      opacity: 0;
                      visibility: hidden;

                      ${choices.length === 1 &&
                      css`
                        pointer-events: none;
                      `};
                    `}
                    onClick={() => {
                      const newChoices = cloneDeep(choices);
                      newChoices[index] = {
                        ...newChoices[index],
                      };
                      const removeItem = choices.filter(
                        (item) => item.id !== newChoices[index].id
                      );
                      setChoices(removeItem);
                      updateChoices(props.element.id, removeItem);
                    }}
                  >
                    <CancelIcon
                      css={css`
                        width: ${calculateProportionalSize(
                          5,
                          canvasSize.width
                        )}px;
                        height: ${calculateProportionalSize(
                          5,
                          canvasSize.width
                        )}px;
                      `}
                    />
                  </button>
                </div>
              </div>
            );
          })}

          <button
            css={css`
              font-weight: 600;
              border: none;
              background: black;
              color: #fff;
              padding: ${calculateProportionalSize(6, canvasSize.width)}px
                ${calculateProportionalSize(20, canvasSize.width)}px;
              border-radius: ${calculateProportionalSize(
                20,
                canvasSize.width
              )}px;
              cursor: pointer;

              ${choices.length >= 5 &&
              css`
                color: #ccc;
                cursor: not-allowed;
              `};
            `}
            style={{
              fontSize: calculateProportionalSize(12, canvasSize.width),
            }}
            onClick={() => {
              if (choices.length < 5) {
                const newChoices = cloneDeep(choices);

                newChoices.push({
                  id: uuid(),
                  text: t("poll.text"),
                  is_correct: false,
                });
                setChoices(newChoices);
                updateChoices(props.element.id, newChoices);
              }
            }}
          >
            <PlusIcon
              css={css`
                width: ${calculateProportionalSize(11, canvasSize.width)}px;
                height: ${calculateProportionalSize(11, canvasSize.width)}px;
                font-weight: 600;
              `}
            />
            &nbsp; {t("poll.add-choice")}
          </button>
        </div>
      </div>
      <div style={{ fontSize: calculateProportionalSize(8, canvasSize.width) }}>
        {t("poll.disclaimer")}
      </div>
    </div>
  );
}
