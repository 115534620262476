import React from "react";
import { css } from "styled-components/macro";
import { Loader } from "../../components/Loader";

export function Button(props: {
  type?: "button" | "submit";
  children?: React.ReactNode;
  title?: string;
  name?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  icon?: React.ReactNode;
  iconRight?: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
  readonly?: boolean;
}) {
  return (
    <button
      type={props.type || "button"}
      name={props.name}
      title={props.title}
      css={css`
        border: none;
        font-size: 16px;
        padding: 10px 20px;
        background: #e95b2e;
        border-radius: 30px;
        font-weight: bold;
        cursor: pointer;
        min-width: 7em;
        :disabled {
          cursor: default;
          background: #858585;
        }
      `}
      disabled={props.disabled || props.isSubmitting}
      className={props.className}
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
    >
      {props.isSubmitting ? (
        <Loader size={15} />
      ) : (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          {props.icon && !props.iconRight && (
            <span
              css={css`
                display: flex;
                ${props.children &&
                css`
                  margin-right: 10px;
                `}
              `}
            >
              {props.icon}
            </span>
          )}

          <span
            css={css`
              display: flex;
              vertical-align: middle;
            `}
          >
            {props.children}
          </span>

          {props.icon && props.iconRight && (
            <span
              css={css`
                display: flex;
                ${props.children &&
                css`
                  margin-left: 10px;
                `}
              `}
            >
              {props.icon}
            </span>
          )}
        </div>
      )}
    </button>
  );
}
