import { css } from "styled-components/macro";

export function InputLabel(props: {
  label?: string;
  description?: string;
  className?: string;
}) {
  if (!props.label) return null;
  let label = props.label?.trim();

  if (label && label[label.length - 1] === ":") {
    label = label.slice(0, label.length - 1);
  }

  return (
    <div
      className={props.className}
      css={css`
        margin-bottom: 16px;
      `}
    >
      <div
        css={css`
          font-size: 16px;
          font-weight: 400;
        `}
      >
        {label}
      </div>
      {props.description && (
        <div
          css={css`
            font-size: 16px;
            color: #9c9c9c;
          `}
        >
          {props.description}
        </div>
      )}
    </div>
  );
}
