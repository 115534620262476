import { maybeAuthorised } from "../../request";
import { Enterprise } from "../../types/Enterprise";

export async function getEnterprise() {
  const API = maybeAuthorised();

  return await API.request<Enterprise>({
    method: "GET",
    url: `/api/enterprise/theme/`,
  });
}
