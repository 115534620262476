import { authorised } from "../../request";
import { Image, ImageType } from "../../types/Image";
import { buildQueryString } from "../../helpers/buildQueryString";

type Args = {
  limit?: number;
  offset?: number;
  name?: string | null;
  type?: ImageType;
  tag?: string;
};

export async function getImages(args: Args) {
  const API = authorised();

  return API.request<{
    count: number;
    next: string | null;
    previous: string | null;
    results: Image[];
  }>({
    method: "GET",
    url: `/api/studio/images/?${buildQueryString(args)}`,
  });
}
