import { authorised } from "../../request";
import { File as FileType } from "../../types/File";

type Args = {
  file: File;
  title?: string | null;
  duration?: number;
  onUploadProgress?: (progressEvent: any) => void;
};

export async function uploadFile({
  file,
  title,
  duration,
  onUploadProgress,
}: Args) {
  const API = authorised();
  const formData = new FormData();

  formData.append("file", file);

  if (title) {
    formData.append("title", title);
  }

  if (duration) {
    formData.append("duration", String(duration));
  }

  const response = await API.request<FileType>({
    method: "POST",
    url: `/api/studio/files/upload/`,
    data: formData,
    onUploadProgress,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
}
