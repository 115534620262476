import { css } from "styled-components/macro";
import { ReactComponent as IconFacebook } from "../assets/icons/IconFacebook.svg";
import { ReactComponent as IconLinkedin } from "../assets/icons/IconLinkedIn.svg";
import { ReactComponent as IconTwitter } from "../assets/icons/IconTwitter.svg";
import { ReactComponent as IconXing } from "../assets/icons/IconXing.svg";
import { ReactComponent as IconLink } from "../assets/icons/IconLink.svg";
import { VideoElementUserTag } from "../VideoEditor/types/Video";
import { useTranslation } from "react-i18next";

export function UserTagCard(props: {
  tag: VideoElementUserTag;
  onInteraction?: () => void;
}) {
  const { tag } = props;
  const responsibilities = tag.responsibilities.split(",");
  const { t } = useTranslation();

  return (
    <div>
      <h4>{tag.name}</h4>
      {tag.email && (
        <h5
          css={css`
            font-size: 11px;
            font-weight: normal;
          `}
        >
          <a href={`mailto:${tag.email}`} rel="noopener noreferrer">
            {tag.email}{" "}
          </a>
        </h5>
      )}
      {responsibilities.filter((el) => el.trim().length > 0).length > 0 && (
        <div>
          {responsibilities.map((responsibility, index) => (
            <div
              key={index}
              css={css`
                display: inline-block;
                padding: 2px 5px;
                border-radius: 13px;
                margin-right: 5px;
                margin-bottom: 5px;
                font-size: 11px;
                color: #fff;

                &:nth-child(1) {
                  background-color: #ff812f;
                }

                &:nth-child(2) {
                  background-color: #c9b1ff;
                }

                &:nth-child(3) {
                  background-color: #f8b856;
                }
              `}
            >
              {responsibility}
            </div>
          ))}
        </div>
      )}
      <div
        css={css`
          display: flex;
          flex-flow: row wrap;
          margin-top: 10px;
          justify-content: space-between;

          a {
            margin-right: 5px;
          }
        `}
      >
        <div>
          {tag.twitter_url && (
            <a
              href={tag.twitter_url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={props.onInteraction}
            >
              <IconTwitter />
            </a>
          )}
          {tag.facebook_url && (
            <a
              href={tag.facebook_url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={props.onInteraction}
            >
              <IconFacebook />
            </a>
          )}
          {tag.linkedin_url && (
            <a
              href={tag.linkedin_url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={props.onInteraction}
            >
              <IconLinkedin />
            </a>
          )}
          {tag.xing_url && (
            <a
              href={tag.xing_url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={props.onInteraction}
            >
              <IconXing />
            </a>
          )}
        </div>
        <div>
          {tag.relevant_url && (
            <button
              css={css`
                background: #ffffff;
                box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
                border-radius: 13px;
                border: none;
                padding: 5px 10px;
                font-size: 11px;
                color: #e95b2e;
                cursor: pointer;
              `}
              onClick={(e) => {
                e.stopPropagation();
                window.open(tag.relevant_url, "_blank");

                if (props.onInteraction) {
                  props.onInteraction();
                }
              }}
            >
              <IconLink /> {t("userTag.click_here")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
