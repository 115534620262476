import React, { useCallback, useEffect, useRef, useState } from "react";
import { css } from "styled-components/macro";
import { CommentPostRequest, CommentVisibility } from "../../../types/Comment";
import { useComments } from "../../contexts/CommentsContext";
import { useClickOutside } from "../../hooks/useClickOutside";
import { ReactComponent as CommentDialod } from "../../assets/icons/CommentDialog.svg";
import { CommentInput } from ".";
import { theme } from "../../../themes/variables";

type CommentDialogProps = {
  top: number;
  left: number;
  scene_id: string;
  onClose: () => void;
};

export function CommentDialog(props: CommentDialogProps) {
  const inputRef = useRef(null);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [extendedInput, setExtendedIntput] = useState<boolean>(false);
  const [content, setContent] = useState<string>("");

  const { commentApi } = useComments();

  useClickOutside(inputRef, () => {
    props.onClose();
  });

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.onClose();
      }
    };
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [props]);

  const clearInput = useCallback(() => {
    setSubmitting(false);
    setContent("");
    props.onClose();
  }, [props]);

  const onSubmit = useCallback(() => {
    setSubmitting(true);

    const comment: CommentPostRequest = {
      scene_id: props.scene_id,
      top: Math.ceil(props.top),
      left: Math.ceil(props.left),
      visibility: CommentVisibility.CREATOR,
      content: content,
    };

    commentApi.createEditorComment(comment);

    clearInput();
  }, [commentApi, content, props, clearInput]);

  return (
    <div
      ref={inputRef}
      css={css`
        display: flex;
      `}
    >
      <CommentDialod
        css={css`
          margin: 0 10px;
        `}
      />

      <div
        css={css`
          display: flex;
          width: 250px;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          border-radius: 7px;
          padding: 0 10px;
          background-color: ${theme.colors.white};
        `}
      >
        <CommentInput
          comment={content}
          isSubmitting={isSubmitting}
          extended={extendedInput}
          setExtended={(value: boolean) => setExtendedIntput(value)}
          onSubmit={onSubmit}
          onChange={(el) => setContent(el.target.value)}
          clearInput={clearInput}
        />
      </div>
    </div>
  );
}
