import { authorised } from "../../request";

type Args = {
  file: File;
  user_id: number;
  video_id: string;
};

export async function uploadAiFile({ file, user_id, video_id }: Args) {
  const API = authorised();
  const formData = new FormData();

  formData.append("file", file);
  formData.append("user_id", String(user_id));
  formData.append("video_id", video_id);

  const response = await API.request<{
    file_id: string;
  }>({
    method: "POST",
    url: `/api/studio/themis/${video_id}/ml/upload_file/`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
}
