import { css } from "styled-components/macro";
import { PageLayout } from "../components/PageLayout";
import { SEO } from "../components/SEO";
import { ReactComponent as ArrowIcon } from "../assets/icons/Arrow.svg";
import { ReactComponent as ArchiveIcon } from "../assets/icons/Archive.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import { Headers, Row, Column } from "../helpers/layout";
import { getVideos, QueryVideoStatus } from "../actions/themi/getVideos";
import { Video } from "../VideoEditor/types/Video";
import { usePagination } from "../hooks/usePagination";
import { pageLoading } from "../helpers/pageLoading";
import { Pagination } from "../components/Pagination";
import { ThemiLongCard } from "../components/ThemiLongCard";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import { FeedbackWidget } from "../components/FeedbackWidget";
import { ThemiSquareCard } from "../components/ThemiSquareCard";

type StatusParams = {
  status: "drafts" | "shared" | "published" | "archived";
};

export function VideosPage() {
  const { t } = useTranslation();
  const { status } = useParams<StatusParams>();
  const history = useHistory();
  const {
    response: { data: videos, error: videosError, refetch: refetchVideos },
    pagination: { onChose, onNext, onPrev, count, page },
  } = usePagination<
    {
      count: number;
      next: null | string;
      previous: null | string;
      results: Video[];
    },
    {
      status: QueryVideoStatus;
    }
  >(getVideos, {
    internalArgs: {
      status:
        status === "drafts"
          ? "MY_DRAFTS"
          : status === "shared"
          ? "SHARED"
          : status === "published"
          ? "PUBLISHED"
          : status === "archived"
          ? "ARCHIVED"
          : "ALL",
    },
    limit: status === "published" ? 8 : status !== "archived" ? 4 : 0,
  });

  if (!videos || videosError) {
    return pageLoading(videosError);
  }

  const title =
    status === "drafts"
      ? t("myThemi.dashboard.title.themi")
      : status === "shared"
      ? t("myThemi.dashboard.title.shared-themi")
      : status === "published"
      ? t("pagelayout.published-button")
      : status === "archived"
      ? ""
      : t("myThemi.dashboard.title.themi");

  return (
    <>
      <PageLayout>
        <SEO>
          <title>{title}</title>
        </SEO>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Link to="/themi/">
            <ArrowIcon />
          </Link>
          <Link
            to="/themi"
            css={css`
              font-size: 20px;
              font-weight: 600;
              letter-spacing: -0.05em;
              margin-left: 20px;
            `}
          >
            {t("myThemi.dashboard.button.back")}
          </Link>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 50px;
          `}
        >
          <Headers.H4
            css={css`
              font-size: 20px;
              font-weight: 500;
            `}
          >
            {title}
          </Headers.H4>
          {status === "published" && (
            <Button
              icon={<ArchiveIcon />}
              css={css`
                background-color: transparent;
                color: #7f7f7f;
                font-size: 20px;
                font-weight: 400;
              `}
              hoverStyles="none"
              onClick={() => {
                history.push("/themi/archived/");
              }}
            >
              {t("myThemi.dashboard.button.archive")}
            </Button>
          )}
        </div>

        {status !== "archived" ? (
          <Column>
            <div
              css={css`
                width: 100%;
                max-width: 607px;
                margin-top: 10px;
                ${videos.results.length === 0 &&
                css`
                  max-width: 809px;
                  text-align: center;
                  align-self: center;
                `}
              `}
            >
              {videos.results.length > 0 ? (
                videos.results.map((video) => {
                  return (
                    <ThemiLongCard
                      key={video.uuid}
                      video={video}
                      videoStatus={video.publish_status}
                      onArchive={refetchVideos}
                    />
                  );
                })
              ) : (
                <Headers.H4
                  css={css`
                    color: #cdcdcd;
                    font-size: 25px;
                    line-height: 32px;
                    letter-spacing: -0.04em;
                  `}
                >
                  {status === "drafts"
                    ? t("myThemi.dashboard.draftVideos.empty")
                    : t("myThemi.dashboard.sharedVideos.empty")}
                </Headers.H4>
              )}
            </div>
          </Column>
        ) : (
          <div
            css={css`
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              overflow-y: scroll;
              height: calc(100vh - 206px);

              ${videos.results.length === 0 &&
              css`
                max-width: 809px;
                text-align: center;
                align-self: center;
              `}
              ::-webkit-scrollbar {
                height: 100%;
                border-radius: 5px;
                width: 8px;
                margin-left: 36px;
              }

              ::-webkit-scrollbar-track {
                background: #f2f2f2;
                border-radius: 5px;
              }

              ::-webkit-scrollbar-thumb {
                background: #7f7f7f !important;
                border-radius: 5px;
              }

              ::-webkit-scrollbar-thumb:hover {
                background: #7f7f7f;
              }
            `}
          >
            {videos.results.length > 0 ? (
              videos.results.map((video) => {
                return (
                  <ThemiSquareCard
                    key={video.uuid}
                    video={video}
                    videoStatus={status}
                    onArchive={refetchVideos}
                    css={css`
                      margin-bottom: 36px;
                      max-width: 275px;
                    `}
                  />
                );
              })
            ) : (
              <Headers.H4
                css={css`
                  color: #cdcdcd;
                  font-size: 25px;
                  line-height: 32px;
                  letter-spacing: -0.04em;
                `}
              >
                {t("myThemi.dashboard.draftVideos.empty")}
              </Headers.H4>
            )}
          </div>
        )}

        <Row align="center" justify="center">
          <Pagination
            css={css`
              margin-top: 40px;
            `}
            arrows
            currentItemIndex={page}
            itemsCount={count}
            onChose={onChose}
            onNext={onNext}
            onPrev={onPrev}
          />
        </Row>
      </PageLayout>
      <FeedbackWidget />
    </>
  );
}
