import { useHistory } from "react-router";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Video } from "../VideoEditor/types/Video";
import { Headers } from "../helpers/layout";
import { Button } from "../components/Button";
import { Dropdown, DropdownItem } from "../components/Dropdown";
import { ScenePreview } from "../VideoEditor/components/ScenePreview";
import { getVideoScenes } from "../VideoEditor/helpers/video";
import { ReactComponent as ArchiveIcon } from "../assets/icons/DropDown-Archive.svg";
import { ReactComponent as ThreeDotsIcon } from "../assets/icons/ThreeDots.svg";
import { handleArchiveRecover } from "../helpers/handleArchiveRecover";
import { Link } from "react-router-dom";
export function ThemiSquareCard(props: {
  video: Video;
  className?: string;
  videoStatus?: string;
  onArchive: () => Promise<void>;
}) {
  const { video } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const isArchived = video.archived_at !== null;

  const dropdownOptions = [
    {
      label: t("video-player.view"),
      link: `/video/${video?.uuid}`,
      type: "link",
    },
    {
      label: t("video-player.edit"),
      link: `/video-editor/${video?.uuid}`,
      type: "link",
    },
    {
      label: t("drop-down.archive"),
      type: "button",
    },
  ];

  return (
    <div
      css={css`
        display: flex;
        width: 31%;
        margin-right: 3.5%;

        &:nth-child(3n) {
          margin-right: 0;
        }
      `}
      key={video.uuid}
      className={props.className}
    >
      <div
        key={video.uuid}
        css={css`
          width: 100%;
          height: 235px;
          border: 1px solid #e2e2e2;
          border-radius: 11px;
        `}
      >
        <div
          css={css`
            width: 100%;
            height: auto;
            background-color: #d9d9d9;
            border-radius: 11px 11px 0 0;
            cursor: pointer;
            overflow: hidden;
          `}
          onClick={() => {
            history.push(`/video-editor/${video.uuid}`);
          }}
        >
          {video.preview_schema && (
            <ScenePreview
              scene={getVideoScenes(video.preview_schema)[0]}
              width={400}
            />
          )}
        </div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <Headers.H4
            css={css`
              padding-left: 13px;
              padding-top: 2px;
              cursor: pointer;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              font-weight: 600;
              line-height: 28px;
            `}
            onClick={() => {
              history.push(`/video-editor/${video.uuid}`);
            }}
          >
            {video.name}
          </Headers.H4>
          <Dropdown
            // eslint-disable-next-line array-callback-return
            options={dropdownOptions.map((option) => {
              if (option.type === "link" && option.link) {
                return (
                  <Link to={option.link} key={option.label}>
                    <DropdownItem>{option.label}</DropdownItem>
                  </Link>
                );
              }
              if (option.type === "button") {
                return (
                  <Button
                    css={css`
                      border: none;
                      padding: 0;
                      background-color: transparent;
                      color: black;
                      border-radius: 0;
                    `}
                    key={option.label}
                    onClick={async () =>
                      await handleArchiveRecover(video, props.onArchive, t)
                    }
                  >
                    <DropdownItem>
                      {isArchived ? (
                        t("drop-down.recover")
                      ) : (
                        <>
                          <ArchiveIcon
                            css={css`
                              align-self: center;
                              margin-right: 5px;
                            `}
                          />
                          {option.label}
                        </>
                      )}
                    </DropdownItem>
                  </Button>
                );
              }
            })}
          >
            <Button
              css={css`
                display: flex;
                flex-direction: column;
                min-width: 3em;
                background-color: transparent;
                color: #000;
              `}
              hoverStyles="none"
            >
              <ThreeDotsIcon />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
