import { convertToRichText } from "../../../VideoEditor/helpers/video";
import {
  VideoElementState,
  VideoElementText,
} from "../../../VideoEditor/types/Video";
import { css } from "styled-components/macro";

export function ElementTextRenderer(props: {
  element: VideoElementText;
  state: VideoElementState;
}) {
  const { cta } = props.element;
  const passedText = convertToRichText(props.element).text;
  const hasCTA =
    cta &&
    ((cta.type === "link" && cta.url) || (cta.type === "file" && cta.file));

  return (
    <div
      style={{
        overflowWrap: "break-word",
        outline: "none",
        cursor: hasCTA ? "pointer" : "auto",
      }}
      css={css`
        li {
          margin-left: 1.2em;
        }
      `}
      dangerouslySetInnerHTML={{ __html: passedText }}
      onClick={() => {
        if (!cta) return;

        if (cta.type === "link" && cta.url) {
          window.open(cta.url, "_blank");
        } else if (cta.type === "file" && cta.file) {
          window.open(cta.file.file, "_blank");
        }
      }}
    ></div>
  );
}
