import { Timeline } from "./Timeline";
import { PlayButton } from "./PlayButton";
import { css } from "styled-components/macro";
import { usePlayer } from "../contexts/PlayerContext";

export function ControlsBar() {
  const { showControls } = usePlayer();

  return (
    <div
      css={css`
        position: absolute;
        width: 90%;
        max-width: 1018px;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        bottom: -100px;
        transition: all 1s ease-in-out;
        opacity: 0;
        z-index: 1000;

        ${showControls &&
        css`
          bottom: 33px;
          transition: all 0.5s ease-in-out;
          opacity: 1;
        `}
      `}
    >
      <PlayButton />
      <Timeline />
    </div>
  );
}
