import {
  VideoElementPoll,
  VideoElementState,
} from "../../../VideoEditor/types/Video";
import { css } from "styled-components/macro";
import { calculateProportionalSize } from "../../helpers/renderer";
import TickIcon from "../../../VideoEditor/assets/icons/Tick.svg";
import { usePlayer } from "../../contexts/PlayerContext";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { submitPoll } from "../../actions/submitPoll";
import { customToast } from "../../../components/customToast";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { getVotes } from "../../actions/getVotes";
import { sendEvent } from "../../../helpers/tracking";

export function ElementPollRenderer(props: {
  element: VideoElementPoll;
  state: VideoElementState;
}) {
  const { canvasSize } = usePlayer();
  const { t } = useTranslation();
  const [selection, setSelection] = useState<string[]>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { video } = usePlayer();

  useEffect(() => {
    if (video) {
      getVotes(video.uuid, props.element.id).then((response) => {
        if (response.data?.votes?.length > 0) {
          setSelection(response.data.votes);
          setIsSubmitted(true);
        }
      });
    }
  }, [video, props.element.id]);

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
        background: #ffffff;
      `}
      style={{
        borderRadius: calculateProportionalSize(10, canvasSize.width),
        padding: calculateProportionalSize(20, canvasSize.width),
      }}
    >
      <h3
        style={{
          fontSize: calculateProportionalSize(15, canvasSize.width),
          fontWeight: 500,
        }}
        dangerouslySetInnerHTML={{ __html: props.element.title }}
      />
      <div
        style={{
          marginTop: calculateProportionalSize(10, canvasSize.width),
          marginBottom: calculateProportionalSize(10, canvasSize.width),
        }}
      >
        <p
          style={{
            fontSize: calculateProportionalSize(10, canvasSize.width),
            lineHeight: "1.2",
          }}
          dangerouslySetInnerHTML={{ __html: props.element.subtitle }}
        ></p>
        <div
          css={css`
            margin-top: ${calculateProportionalSize(10, canvasSize.width)}px;
          `}
        >
          {props.element.choices &&
            props.element.choices.map((choice, index) => {
              const isChecked = selection.includes(choice.id);

              return (
                <div
                  key={index}
                  css={css`
                    position: relative;
                    padding-right: ${calculateProportionalSize(
                      30,
                      canvasSize.width
                    )}px;
                  `}
                >
                  <label
                    css={css`
                      display: flex;
                      flex-flow: row wrap;
                      align-items: center;
                      background: ${isChecked && isSubmitted
                        ? "#E4FCEA"
                        : "#fff"};
                      border: ${calculateProportionalSize(
                          1,
                          canvasSize.width
                        )}px
                        solid #252424;
                      border-radius: ${calculateProportionalSize(
                        3,
                        canvasSize.width
                      )}px;
                      cursor: ${isSubmitted ? "not-allowed" : "pointer"};

                      &:last-child {
                        margin-bottom: 0;
                      }
                    `}
                    style={{
                      padding: calculateProportionalSize(5, canvasSize.width),
                      marginBottom: calculateProportionalSize(
                        10,
                        canvasSize.width
                      ),
                    }}
                  >
                    <div
                      css={css`
                        flex: 0 0 auto;
                        margin-right: ${calculateProportionalSize(
                          5,
                          canvasSize.width
                        )}px;
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={(e) => {
                          if (isSubmitted) return;

                          e.stopPropagation();

                          if (selection.includes(choice.id)) {
                            setSelection(
                              selection.filter((id) => id !== choice.id)
                            );
                          } else {
                            if (props.element.subtype === "single-choice") {
                              setSelection([choice.id]);
                            } else {
                              setSelection([...selection, choice.id]);
                            }
                          }
                        }}
                        css={css`
                          width: ${calculateProportionalSize(
                            13,
                            canvasSize.width
                          )}px;
                          height: ${calculateProportionalSize(
                            13,
                            canvasSize.width
                          )}px;
                          border-radius: 50%;
                          cursor: pointer;
                          border: ${calculateProportionalSize(
                              1,
                              canvasSize.width
                            )}px
                            solid black;
                          transition: 0.2s all linear;
                          margin-right: ${calculateProportionalSize(
                            5,
                            canvasSize.width
                          )}px;
                          outline: none;
                          appearance: none;
                          content: "";
                          background-image: url(${TickIcon});
                          background-repeat: no-repeat;
                          background-position: center;
                          background-size: 60%;

                          :checked {
                            background-color: #74d048;
                          }
                        `}
                      />
                    </div>

                    <div
                      style={{
                        fontSize: calculateProportionalSize(
                          10,
                          canvasSize.width
                        ),
                        outline: "none",
                        flex: "1 1 10%",
                      }}
                    >
                      {choice.text}
                    </div>
                  </label>
                </div>
              );
            })}
          <button
            css={css`
              font-weight: 600;
              border: none;
              background: black;
              color: #fff;
              padding: ${calculateProportionalSize(6, canvasSize.width)}px
                ${calculateProportionalSize(20, canvasSize.width)}px;
              border-radius: ${calculateProportionalSize(
                20,
                canvasSize.width
              )}px;
              cursor: pointer;

              ${(!selection.length || isSubmitted) &&
              css`
                background: #7f7f7f;
                color: #ccc;
                pointer-events: none;
              `};
            `}
            style={{
              fontSize: calculateProportionalSize(12, canvasSize.width),
            }}
            onClick={async () => {
              if (selection.length && video) {
                try {
                  await submitPoll(video.uuid, props.element.id, {
                    votes: selection,
                  });
                  sendEvent("Interact with Themi", {
                    themiId: video.uuid,
                    feature: props.element.type,
                    subtype: props.element.subtype,
                  });
                  setIsSubmitted(true);
                } catch (error) {
                  customToast.error(
                    t("status.error", {
                      error: serverErrorHandler(error),
                    })
                  );
                }
              }
            }}
          >
            {isSubmitted ? t("poll.submitted") : t("poll.submit")}
          </button>
        </div>
      </div>
      <div
        style={{
          fontSize: calculateProportionalSize(8, canvasSize.width),
        }}
      >
        {t("poll.disclaimer")}
      </div>
    </div>
  );
}
