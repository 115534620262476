import axios from "axios";
import { THEMIS_AUTH_TOKEN } from "./contexts/UserContext";

export const config = {
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "accept-language": process.env.REACT_APP_LANGUAGE,
  },
};

export const notAuthorised = axios.create(config);

export function authorised() {
  const token = localStorage.getItem(THEMIS_AUTH_TOKEN);

  if (!token) {
    throw new Error("Not Authorized");
  }

  return axios.create({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Token ${token}`,
    },
  });
}

export function maybeAuthorised() {
  const token = localStorage.getItem(THEMIS_AUTH_TOKEN);

  if (token) {
    return authorised();
  }

  return notAuthorised;
}
