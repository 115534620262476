import { authorised } from "../../request";

type Args = {
  videoId: string;
};

export enum UploadStatus {
  UPLOADING = "uploading",
  PARSING = "parsing",
  SUMMARIZING = "summarizing",
  GENERATING_LAYOUT = "generating_layout",
  ERROR = "error",
  COMPLETED = "completed",
}

export type FileStatus = {
  file_id: string;
  filename: string;
  status: UploadStatus;
  started_at: string;
  completed_at: string;
};

export async function getAiFileStatus(args: Args) {
  const API = authorised();

  return API.request<FileStatus[]>({
    method: "GET",
    url: `/api/studio/themis/${args.videoId}/ml/status/`,
  });
}
