import { authorised } from "../../request";
import { buildQueryString } from "../helpers/buildQueryString";
import { UserMeta } from "../../types/UserProfile";

type Args = {
  limit?: number;
  offset?: number;
  query?: string;
};

export async function getUsersBySearch(args: Args) {
  const API = authorised();

  return API.request<{
    count: number;
    next: string | null;
    previous: string | null;
    results: UserMeta[];
  }>({
    method: "GET",
    url: `/api/studio/users/?${buildQueryString(args)}`,
  });
}
