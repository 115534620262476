import { VideoElementGif, VideoElementState } from "../../types/Video";
import { css } from "styled-components/macro";

export function ElementGifRenderer(props: {
  element: VideoElementGif;
  state: VideoElementState;
}) {
  return (
    <div
      css={css`
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: auto;
          user-select: none;
        }
      `}
    >
      <img
        src={props.element.image.src.url}
        alt={props.element.image.title}
        draggable={false}
      />
    </div>
  );
}
