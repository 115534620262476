import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button } from "../../VideoEditor/components/Button";
import { InputField } from "./fields/InputField";
import { Column, Row, Spacing } from "../../helpers/layout";

import { VideoElementUserTag } from "../types/Video";
import { ImageUploadField } from "./fields/ImageUploadField";
import { ImageType } from "../types/Image";
import { uploadImage } from "../actions/uploadImage";
import { ReactComponent as IconFacebook } from "../../assets/icons/IconFacebook.svg";
import { ReactComponent as IconLinkedin } from "../../assets/icons/IconLinkedIn.svg";
import { ReactComponent as IconTwitter } from "../../assets/icons/IconTwitter.svg";
import { ReactComponent as IconXing } from "../../assets/icons/IconXing.svg";
import { customToast } from "../../components/customToast";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";

type FormValues = Omit<VideoElementUserTag, "id">;

const FieldWrapper = styled.div`
  flex: 0 0 48%;

  &:first-child {
    margin-right: 4%;
  }
`;
const PlainButton = styled.button<{
  isActive: boolean;
}>`
  background: none;
  border: none;
  padding: 3px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      svg {
        fill: #e95b2e;
      }
    `}
`;

export function UserTagForm(props: {
  initialValues?: FormValues | null;
  onSubmit: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation();
  const [socialInput, setSocialInput] = React.useState<
    "facebook" | "instagram" | "linkedin" | "xing" | "twitter"
  >("facebook");

  return (
    <div
      css={css`
        width: 100%;
      `}
      onClick={(e) => e.stopPropagation()}
    >
      <Formik<FormValues>
        initialValues={
          props.initialValues
            ? props.initialValues
            : {
                name: "",
                email: "",
                responsibilities: "",
                facebook_url: "",
                twitter_url: "",
                linkedin_url: "",
                instagram_url: "",
                relevant_url: "",
                xing_url: "",
                avatar: null,
              }
        }
        validationSchema={Yup.object().shape({
          name: Yup.string().required(),
          facebook_url: Yup.string().url(),
          twitter_url: Yup.string().url(),
          linkedin_url: Yup.string().url(),
          instagram_url: Yup.string().url(),
          relevant_url: Yup.string().url(),
          xing_url: Yup.string().url(),
        })}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          try {
            setSubmitting(true);
            await props.onSubmit(values);
          } catch (error: any) {
            customToast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form noValidate>
            <Column
              gutter={Spacing.m}
              css={css`
                text-align: left;
              `}
            >
              <Row gutter={Spacing.none}>
                <FieldWrapper>
                  <InputField
                    type="text"
                    name="name"
                    placeholder={t("userTagForm.name")}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <InputField
                    type="text"
                    name="email"
                    placeholder={t("userTagForm.email")}
                  />
                </FieldWrapper>
              </Row>
              <Row gutter={Spacing.none}>
                <FieldWrapper>
                  <ImageUploadField
                    label={`${t("account.avatar")}:`}
                    name={"avatar"}
                    type={ImageType.HIDDEN}
                    disableLibrary
                    uploadFn={uploadImage}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <h4>{t("userTagForm.social-media.title")}</h4>
                  <div>
                    <PlainButton
                      type="button"
                      isActive={socialInput === "facebook"}
                      onClick={() => setSocialInput("facebook")}
                    >
                      <IconFacebook />
                    </PlainButton>
                    <PlainButton
                      type="button"
                      isActive={socialInput === "twitter"}
                      onClick={() => setSocialInput("twitter")}
                    >
                      <IconTwitter />
                    </PlainButton>
                    <PlainButton
                      type="button"
                      isActive={socialInput === "linkedin"}
                      onClick={() => setSocialInput("linkedin")}
                    >
                      <IconLinkedin />
                    </PlainButton>
                    <PlainButton
                      type="button"
                      isActive={socialInput === "xing"}
                      onClick={() => setSocialInput("xing")}
                    >
                      <IconXing />
                    </PlainButton>
                  </div>
                  {socialInput === "facebook" && (
                    <InputField
                      type="text"
                      name="facebook_url"
                      placeholder={t("userTagForm.paste") + ` facebook`}
                    />
                  )}
                  {socialInput === "instagram" && (
                    <InputField
                      type="text"
                      name="instagram_url"
                      placeholder={t("userTagForm.paste") + ` instagram`}
                    />
                  )}
                  {socialInput === "linkedin" && (
                    <InputField
                      type="text"
                      name="linkedin_url"
                      placeholder={t("userTagForm.paste") + ` linkedin`}
                    />
                  )}
                  {socialInput === "xing" && (
                    <InputField
                      type="text"
                      name="xing_url"
                      placeholder={t("userTagForm.paste") + ` xing`}
                    />
                  )}
                  {socialInput === "twitter" && (
                    <InputField
                      type="text"
                      name="twitter_url"
                      placeholder={t("userTagForm.paste") + ` twitter`}
                    />
                  )}
                </FieldWrapper>
              </Row>
              <Row gutter={Spacing.none}>
                <FieldWrapper>
                  <h4>{t("userTagForm.responsibilities")}</h4>
                  <h5
                    css={css`
                      color: #cdcdcd;
                    `}
                  >
                    {t("userTagForm.max")}
                  </h5>
                  <InputField
                    type="text"
                    name="responsibilities"
                    placeholder={t("userTagForm.placeholder")}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <h4>{t("userTagForm.link-label")}</h4>
                  <h5>&nbsp;</h5>
                  <InputField
                    type="text"
                    name="relevant_url"
                    placeholder={t("userTagForm.paste")}
                  />
                </FieldWrapper>
              </Row>

              <Row justify="flex-end" align="center">
                <Button
                  isSubmitting={isSubmitting}
                  disabled={!isValid}
                  type="submit"
                  css={css`
                    color: #fff;
                  `}
                >
                  {t("userTagForm.save")}
                </Button>
              </Row>
            </Column>
          </Form>
        )}
      </Formik>
    </div>
  );
}
